import type Konva from 'konva'
import type { ConnectionDto, ShapeDtoRequest } from '../../../../generated/backend'
import { ShapeType } from '../../../../generated/backend'

import { getPosition } from '../../../../helpers'
import { DIFF_TO_SNAP, LINE_HEIGHT, LINE_WIDTH } from '../../../../../consts'
import { drawingShape, setSelectedTool, startDraw, type useAppDispatch } from '../../../../store'
import { type IShapeDraft } from '../../../../store/slice/drawSlice'
import { type Vector2d } from 'konva/lib/types'
import { finishDraw } from '../../../../store/actions/shapes/shapeActions'
import { TOOLS } from '../../../header/tools/consts'

const getIntersections = (e: Konva.KonvaEventObject<MouseEvent>): ConnectionDto[] => {
	const pointerPosition = getPosition(e)
	const targetAttrs = e.target.attrs
	const intersections: Array<Partial<ConnectionDto>> = []
	const shapeDraft = e.target.getStage()?.findOne('.DRAFT')

	if (targetAttrs.shapeType === ShapeType.Rectangle && targetAttrs.connections.length > 0) {
		// У прямоугольника должен быть только один connection,
		// поэтому забираем [0]. В случае, если shapeType = Line, их может быть больше
		intersections.push(targetAttrs.connections[0] as ConnectionDto)
		return intersections
	}
	if (targetAttrs.shapeType === ShapeType.Rectangle && targetAttrs.connections.length === 0) {
		// При создании connection в прямоугольнике, x и y connection'а равны центру прямоугольника
		const id: string[] = [targetAttrs.id]
		const x: number = targetAttrs.x + targetAttrs.width / 2
		const y: number = targetAttrs.y + targetAttrs.height / 2
		intersections.push({
			shapes_id: id,
			x,
			y,
		})
		return intersections
	}
	if (targetAttrs.shapeType === ShapeType.Line) {
		if (shapeDraft?.attrs.width && shapeDraft?.attrs.height) {
			if (shapeDraft.attrs.width === LINE_WIDTH) {
				intersections.push({
					shapes_id: [targetAttrs.id],
					x: shapeDraft.attrs.x + 5,
					y: targetAttrs.y + 5,
				})
			} else {
				intersections.push({
					shapes_id: [targetAttrs.id],
					x: targetAttrs.x + 5,
					y: shapeDraft.attrs.y + 5,
				})
			}
		} else {
			if (targetAttrs.width === LINE_WIDTH) {
				intersections.push({
					shapes_id: [targetAttrs.id],
					x: targetAttrs.x + 5,
					y: pointerPosition.y + 5,
				})
			} else {
				intersections.push({
					shapes_id: [targetAttrs.id],
					x: pointerPosition.x + 5,
					y: targetAttrs.y + 5,
				})
			}
		}
		return intersections
	}
	if (targetAttrs.shapeType === 'Connector') {
		intersections.push({
			id: targetAttrs.id,
		})
	}
	return intersections
}

export const startDrawLine =
	(e: Konva.KonvaEventObject<MouseEvent>, imageId: string) => (dispatch: ReturnType<typeof useAppDispatch>) => {
		const startDrawingPosition = getPosition(e)
		// @ts-expect-error rotate
		const shapeDraft: IShapeDraft = {
			x: startDrawingPosition.x,
			y: startDrawingPosition.y,
			width: LINE_WIDTH,
			height: LINE_HEIGHT,
			shape_type: ShapeType.Line,
			image_drawing_id: imageId,
			connections: getIntersections(e),
		}

		dispatch(startDraw(shapeDraft))
	}

const drawLineToLine = (e: Konva.KonvaEventObject<MouseEvent>): Vector2d => {
	const position = getPosition(e)
	const lineIsVertical = e.target.attrs.width === LINE_WIDTH

	if (lineIsVertical) {
		position.x = e.target.attrs.x
	} else {
		position.y = e.target.attrs.y
	}
	return position
}

const drawLineToRectgangle = (e: Konva.KonvaEventObject<MouseEvent>, shapeDraft: IShapeDraft): Vector2d => {
	const position = getPosition(e)
	const rectangleAttrs = e.target.attrs

	const rect = {
		x: {
			left: rectangleAttrs.x,
			right: rectangleAttrs.x + rectangleAttrs.width,
		},
		y: {
			top: rectangleAttrs.y,
			bottom: rectangleAttrs.y + rectangleAttrs.height,
		},
	}

	if (shapeDraft.x && shapeDraft.y) {
		if (rect.x.left < shapeDraft.x && shapeDraft.x > rect.x.right) {
			if (shapeDraft.y < rect.y.top && Math.abs(rect.y.top - position.y) < DIFF_TO_SNAP) {
				position.y = rect.y.top
			}
			if (shapeDraft.y > rect.y.bottom && Math.abs(rect.y.bottom - position.y) < DIFF_TO_SNAP) {
				position.y = rect.y.bottom
			}
		}

		if (rect.y.top < shapeDraft.y && shapeDraft.y < rect.y.bottom) {
			if (shapeDraft.x < rect.x.left) {
				position.x = rect.x.left
			}
			if (shapeDraft.x > rect.x.right) {
				position.x = rect.x.right
			}
		}
	}

	return position
}

const getLineSize = (shapeDraft: IShapeDraft, position: Vector2d): { width: number; height: number } => {
	const newSize = {
		width: LINE_WIDTH,
		height: LINE_HEIGHT,
	}

	if (shapeDraft.x && shapeDraft.y) {
		const angle = (Math.atan2(shapeDraft.y - position.y, shapeDraft.x - position.x) * 180) / Math.PI + 180
		if ((angle > 225 && angle < 315) || (angle > 45 && angle < 135)) {
			newSize.height = position.y - shapeDraft.y
		} else {
			newSize.width = position.x - shapeDraft.x
		}
	}

	return newSize
}

export const drawLine =
	(e: Konva.KonvaEventObject<MouseEvent>, shapeDraft: IShapeDraft) => (dispatch: ReturnType<typeof useAppDispatch>) => {
		let position: Vector2d = getPosition(e)

		if (e.target.attrs.shapeType === ShapeType.Line) {
			position = drawLineToLine(e)
		}
		if (e.target.attrs.shapeType === ShapeType.Rectangle) {
			position = drawLineToRectgangle(e, shapeDraft)
		}

		const newLineSize = getLineSize(shapeDraft, position)
		dispatch(drawingShape(newLineSize))
	}

export const finishDrawLine =
	(e: Konva.KonvaEventObject<MouseEvent>, draft: IShapeDraft) =>
	async (dispatch: ReturnType<typeof useAppDispatch>) => {
		const entityForCreate: ShapeDtoRequest = {
			height: draft.height,
			image_drawing_id: draft.image_drawing_id,
			rotate: 0,
			shape_type: draft.shape_type,
			width: draft.width,
			x: draft.x,
			y: draft.y,
		}
		const connections = [...draft.connections, ...getIntersections(e)]

		if (draft.width && draft.x && draft.width < 0) {
			entityForCreate.x = draft.x + draft.width
			entityForCreate.width = Math.abs(draft.width)
		}
		if (draft.height && draft.y && draft.height < 0) {
			entityForCreate.y = draft.y + draft.height
			entityForCreate.height = Math.abs(draft.height)
		}

		await dispatch(
			finishDraw({
				draft: entityForCreate,
				connections,
			})
		)
		dispatch(setSelectedTool(TOOLS.DEFAULT))
	}
