import React, { type FC } from 'react'
import { Rect } from 'react-konva'
import { useAppSelector } from '../../../store'
import { REGULAR_DEFAULT_COLOR } from '../../../../consts'

const BaseDraft: FC = () => {
	const draft = useAppSelector(state => state.draw.stage.draft)

	return (
		<Rect
			x={draft?.x}
			y={draft?.y}
			width={draft?.width}
			height={draft?.height}
			fill={REGULAR_DEFAULT_COLOR}
			name='DRAFT'
		/>
	)
}

export default BaseDraft
