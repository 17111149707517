/* tslint:disable */
/* eslint-disable */
/**
 * RBI Backend API
 * иерархия оборудование компоненты
 *
 * The version of the OpenAPI document: 0.1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import type { Configuration } from './configuration';
import type { AxiosPromise, AxiosInstance, RawAxiosRequestConfig } from 'axios';
import globalAxios from 'axios';
// Some imports not used depending on template conditions
// @ts-ignore
import { DUMMY_BASE_URL, assertParamExists, setApiKeyToObject, setBasicAuthToObject, setBearerAuthToObject, setOAuthToObject, setSearchParams, serializeDataIfNeeded, toPathString, createRequestFunction } from './common';
import type { RequestArgs } from './base';
// @ts-ignore
import { BASE_PATH, COLLECTION_FORMATS, BaseAPI, RequiredError, operationServerMap } from './base';

/**
 * 
 * @export
 * @enum {string}
 */

export const AuthType = {
    Native: 'NATIVE',
    Ldap: 'LDAP',
    Oauth: 'OAUTH'
} as const;

export type AuthType = typeof AuthType[keyof typeof AuthType];


/**
 * 
 * @export
 * @interface ChunkUploadData
 */
export interface ChunkUploadData {
    /**
     * 
     * @type {string}
     * @memberof ChunkUploadData
     */
    'e_tag': string;
    /**
     * 
     * @type {number}
     * @memberof ChunkUploadData
     */
    'part_number': number;
    /**
     * 
     * @type {number}
     * @memberof ChunkUploadData
     */
    'chunk_size': number;
}
/**
 * 
 * @export
 * @interface ComponentDtoRequestIntegration
 */
export interface ComponentDtoRequestIntegration {
    /**
     * 
     * @type {string}
     * @memberof ComponentDtoRequestIntegration
     */
    'external_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentDtoRequestIntegration
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof ComponentDtoRequestIntegration
     */
    'color'?: string;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'number'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentDtoRequestIntegration
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentDtoRequestIntegration
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentDtoRequestIntegration
     */
    'component_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentDtoRequestIntegration
     */
    'component_form'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'component_diameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'minimum_wall_thickness'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'nominal_wall_thickness'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'rejected_wall_thickness'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'actual_wall_thickness'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'design_corrosion_rate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'actual_corrosion_rate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'fluid_velocity'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentDtoRequestIntegration
     */
    'thermal_insulation'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentDtoRequestIntegration
     */
    'thermal_insulation_type'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentDtoRequestIntegration
     */
    'post_weld_heat_treatment'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentDtoRequestIntegration
     */
    'ambient_condition'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentDtoRequestIntegration
     */
    'stainless_coil'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentDtoRequestIntegration
     */
    'heat_tracer_fqc'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentDtoRequestIntegration
     */
    'heat_tracer_type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'residual_life'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentDtoRequestIntegration
     */
    'manufacturing_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentDtoRequestIntegration
     */
    'commissioning_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentDtoRequestIntegration
     */
    'repair_replacement_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentDtoRequestIntegration
     */
    'repair_replacement_description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentDtoRequestIntegration
     */
    'last_inspection_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentDtoRequestIntegration
     */
    'last_internal_inspection_date'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'operating_temperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'design_temperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'actual_temperature_min'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'actual_temperature_max'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'design_pressure'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'operating_pressure'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentDtoRequestIntegration
     */
    'weld_joint_efficiency'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentDtoRequestIntegration
     */
    'tested_on_hic'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ComponentResponse
 */
export interface ComponentResponse {
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'external_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'color': string;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'number'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'code'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'component_type'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'component_form'?: string | null;
    /**
     * 
     * @type {EquipmentDto}
     * @memberof ComponentResponse
     */
    'equipment'?: EquipmentDto | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'component_diameter'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'minimum_wall_thickness'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'nominal_wall_thickness'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'rejected_wall_thickness'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'actual_wall_thickness'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'design_corrosion_rate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'actual_corrosion_rate'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'fluid_velocity'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentResponse
     */
    'thermal_insulation'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'thermal_insulation_type'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentResponse
     */
    'post_weld_heat_treatment'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'ambient_condition'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentResponse
     */
    'stainless_coil'?: boolean | null;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentResponse
     */
    'heat_tracer_fqc'?: boolean | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'heat_tracer_type'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'residual_life'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'manufacturing_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'commissioning_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'repair_replacement_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'repair_replacement_description'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'last_inspection_date'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ComponentResponse
     */
    'last_internal_inspection_date'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'operating_temperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'design_temperature'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'actual_temperature_min'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'actual_temperature_max'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'design_pressure'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'operating_pressure'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ComponentResponse
     */
    'weld_joint_efficiency'?: number | null;
    /**
     * 
     * @type {boolean}
     * @memberof ComponentResponse
     */
    'tested_on_hic'?: boolean | null;
}
/**
 * 
 * @export
 * @interface ConnectionDto
 */
export interface ConnectionDto {
    /**
     * 
     * @type {string}
     * @memberof ConnectionDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDto
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDto
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDto
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDto
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof ConnectionDto
     */
    'color': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof ConnectionDto
     */
    'shapes_id'?: Array<string>;
}
/**
 * 
 * @export
 * @interface ConnectionDtoRequest
 */
export interface ConnectionDtoRequest {
    /**
     * 
     * @type {number}
     * @memberof ConnectionDtoRequest
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDtoRequest
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDtoRequest
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ConnectionDtoRequest
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof ConnectionDtoRequest
     */
    'color': string;
}
/**
 * 
 * @export
 * @interface CorrosionLoopDto
 */
export interface CorrosionLoopDto {
    /**
     * 
     * @type {string}
     * @memberof CorrosionLoopDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof CorrosionLoopDto
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CorrosionLoopDto
     */
    'code': string;
    /**
     * 
     * @type {string}
     * @memberof CorrosionLoopDto
     */
    'color': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CorrosionLoopDto
     */
    'component_ids'?: Array<string> | null;
    /**
     * 
     * @type {Array<ComponentResponse>}
     * @memberof CorrosionLoopDto
     */
    'components'?: Array<ComponentResponse>;
    /**
     * 
     * @type {number}
     * @memberof CorrosionLoopDto
     */
    'components_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof CorrosionLoopDto
     */
    'sort'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof CorrosionLoopDto
     */
    'entries_count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof CorrosionLoopDto
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface CorrosionLoopDtoRequestIntegration
 */
export interface CorrosionLoopDtoRequestIntegration {
    /**
     * 
     * @type {string}
     * @memberof CorrosionLoopDtoRequestIntegration
     */
    'external_id': string;
    /**
     * 
     * @type {string}
     * @memberof CorrosionLoopDtoRequestIntegration
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof CorrosionLoopDtoRequestIntegration
     */
    'code': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof CorrosionLoopDtoRequestIntegration
     */
    'component_external_ids'?: Array<string> | null;
    /**
     * 
     * @type {string}
     * @memberof CorrosionLoopDtoRequestIntegration
     */
    'description'?: string | null;
}
/**
 * 
 * @export
 * @interface DiagramComponent
 */
export interface DiagramComponent {
    /**
     * 
     * @type {string}
     * @memberof DiagramComponent
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof DiagramComponent
     */
    'name': string;
    /**
     * 
     * @type {string}
     * @memberof DiagramComponent
     */
    'color': string;
    /**
     * 
     * @type {Array<string>}
     * @memberof DiagramComponent
     */
    'shape_ids': Array<string>;
    /**
     * 
     * @type {DiagramComponentType}
     * @memberof DiagramComponent
     */
    'diagram_component_type': DiagramComponentType;
}


/**
 * 
 * @export
 * @enum {string}
 */

export const DiagramComponentType = {
    Symbol: 'Symbol',
    RbiCorrosionLoop: 'RbiCorrosionLoop',
    RbiComponent: 'RBIComponent',
    RbiEquipment: 'RBIEquipment',
    HazopNode: 'HazopNode',
    HazopEquipment: 'HazopEquipment'
} as const;

export type DiagramComponentType = typeof DiagramComponentType[keyof typeof DiagramComponentType];


/**
 * 
 * @export
 * @interface EquipmentDto
 */
export interface EquipmentDto {
    /**
     * 
     * @type {string}
     * @memberof EquipmentDto
     */
    'id': string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDto
     */
    'external_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDto
     */
    'code_rcm': string;
    /**
     * 
     * @type {number}
     * @memberof EquipmentDto
     */
    'components_count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDto
     */
    'equipment_type'?: string | null;
    /**
     * 
     * @type {HierarchyItemDto}
     * @memberof EquipmentDto
     */
    'hierarchy_item'?: HierarchyItemDto | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDto
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDto
     */
    'color': string;
}
/**
 * 
 * @export
 * @interface EquipmentDtoRequest
 */
export interface EquipmentDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof EquipmentDtoRequest
     */
    'external_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDtoRequest
     */
    'name'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDtoRequest
     */
    'code_rcm': string;
    /**
     * 
     * @type {number}
     * @memberof EquipmentDtoRequest
     */
    'components_count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDtoRequest
     */
    'equipment_type'?: string;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDtoRequest
     */
    'comment'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof EquipmentDtoRequest
     */
    'color'?: string;
}
/**
 * 
 * @export
 * @interface FileDto
 */
export interface FileDto {
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof FileDto
     */
    'filename': string;
    /**
     * 
     * @type {number}
     * @memberof FileDto
     */
    'size': number;
}
/**
 * 
 * @export
 * @interface HTTPValidationError
 */
export interface HTTPValidationError {
    /**
     * 
     * @type {Array<ValidationError>}
     * @memberof HTTPValidationError
     */
    'detail'?: Array<ValidationError>;
}
/**
 * 
 * @export
 * @interface HierarchyItemDto
 */
export interface HierarchyItemDto {
    /**
     * 
     * @type {string}
     * @memberof HierarchyItemDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HierarchyItemDto
     */
    'external_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HierarchyItemDto
     */
    'name'?: string | null;
    /**
     * 
     * @type {HierarchyItemDto}
     * @memberof HierarchyItemDto
     */
    'parent'?: HierarchyItemDto | null;
    /**
     * 
     * @type {number}
     * @memberof HierarchyItemDto
     */
    'items_count'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HierarchyItemDto
     */
    'equipment_count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HierarchyItemDto
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface HierarchyItemDtoRequest
 */
export interface HierarchyItemDtoRequest {
    /**
     * 
     * @type {string}
     * @memberof HierarchyItemDtoRequest
     */
    'external_id'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HierarchyItemDtoRequest
     */
    'name'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof HierarchyItemDtoRequest
     */
    'parent'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof HierarchyItemDtoRequest
     */
    'items_count'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof HierarchyItemDtoRequest
     */
    'equipment_count'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof HierarchyItemDtoRequest
     */
    'code'?: string | null;
}
/**
 * 
 * @export
 * @interface ImageDrawingFileDto
 */
export interface ImageDrawingFileDto {
    /**
     * 
     * @type {string}
     * @memberof ImageDrawingFileDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ImageDrawingFileDto
     */
    'size': number;
    /**
     * 
     * @type {string}
     * @memberof ImageDrawingFileDto
     */
    'filename': string;
    /**
     * 
     * @type {number}
     * @memberof ImageDrawingFileDto
     */
    'dpi': number;
    /**
     * 
     * @type {string}
     * @memberof ImageDrawingFileDto
     */
    'parent_pdf_id'?: string | null;
    /**
     * 
     * @type {PdfDrawingFileDto}
     * @memberof ImageDrawingFileDto
     */
    'parent_pdf'?: PdfDrawingFileDto | null;
    /**
     * 
     * @type {number}
     * @memberof ImageDrawingFileDto
     */
    'image_number'?: number;
}
/**
 * 
 * @export
 * @interface IntegrationImportDtoRequest
 */
export interface IntegrationImportDtoRequest {
    /**
     * 
     * @type {HierarchyItemDtoRequest}
     * @memberof IntegrationImportDtoRequest
     */
    'hierarchy_item'?: HierarchyItemDtoRequest | null;
    /**
     * 
     * @type {EquipmentDtoRequest}
     * @memberof IntegrationImportDtoRequest
     */
    'equipment': EquipmentDtoRequest;
    /**
     * 
     * @type {Array<ComponentDtoRequestIntegration>}
     * @memberof IntegrationImportDtoRequest
     */
    'components': Array<ComponentDtoRequestIntegration>;
}
/**
 * 
 * @export
 * @interface MultipartUploadData
 */
export interface MultipartUploadData {
    /**
     * 
     * @type {string}
     * @memberof MultipartUploadData
     */
    'upload_id': string;
    /**
     * 
     * @type {string}
     * @memberof MultipartUploadData
     */
    'file_key': string;
}
/**
 * 
 * @export
 * @interface PdfDrawingFileDto
 */
export interface PdfDrawingFileDto {
    /**
     * 
     * @type {string}
     * @memberof PdfDrawingFileDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {boolean}
     * @memberof PdfDrawingFileDto
     */
    'is_splitting'?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PdfDrawingFileDto
     */
    'split_success'?: boolean;
    /**
     * 
     * @type {number}
     * @memberof PdfDrawingFileDto
     */
    'page_count'?: number;
    /**
     * 
     * @type {number}
     * @memberof PdfDrawingFileDto
     */
    'size': number;
    /**
     * 
     * @type {number}
     * @memberof PdfDrawingFileDto
     */
    'image_drawing_count'?: number;
    /**
     * 
     * @type {string}
     * @memberof PdfDrawingFileDto
     */
    'filename': string;
}
/**
 * 
 * @export
 * @interface ShapeDto
 */
export interface ShapeDto {
    /**
     * 
     * @type {string}
     * @memberof ShapeDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {ShapeType}
     * @memberof ShapeDto
     */
    'shape_type': ShapeType;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShapeDto
     */
    'points'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeDto
     */
    'rotate': number;
    /**
     * 
     * @type {number}
     * @memberof ShapeDto
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeDto
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeDto
     */
    'x'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeDto
     */
    'y'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShapeDto
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShapeDto
     */
    'image_drawing_id': string;
    /**
     * 
     * @type {Array<ShapeTagDto>}
     * @memberof ShapeDto
     */
    'tags'?: Array<ShapeTagDto>;
}


/**
 * 
 * @export
 * @interface ShapeDtoRequest
 */
export interface ShapeDtoRequest {
    /**
     * 
     * @type {ShapeType}
     * @memberof ShapeDtoRequest
     */
    'shape_type': ShapeType;
    /**
     * 
     * @type {Array<number>}
     * @memberof ShapeDtoRequest
     */
    'points'?: Array<number> | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeDtoRequest
     */
    'rotate': number;
    /**
     * 
     * @type {number}
     * @memberof ShapeDtoRequest
     */
    'width'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeDtoRequest
     */
    'height'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeDtoRequest
     */
    'x'?: number | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeDtoRequest
     */
    'y'?: number | null;
    /**
     * 
     * @type {string}
     * @memberof ShapeDtoRequest
     */
    'label'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShapeDtoRequest
     */
    'image_drawing_id': string;
}


/**
 * 
 * @export
 * @interface ShapeDtoResponse
 */
export interface ShapeDtoResponse {
    /**
     * 
     * @type {Array<ShapeDto>}
     * @memberof ShapeDtoResponse
     */
    'shapes': Array<ShapeDto>;
    /**
     * 
     * @type {Array<DiagramComponent>}
     * @memberof ShapeDtoResponse
     */
    'diagramComponent': Array<DiagramComponent>;
    /**
     * 
     * @type {Array<ConnectionDto>}
     * @memberof ShapeDtoResponse
     */
    'connections': Array<ConnectionDto>;
}
/**
 * 
 * @export
 * @interface ShapeTagDto
 */
export interface ShapeTagDto {
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDto
     */
    'id'?: string | null;
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDto
     */
    'rotate': number;
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDto
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDto
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDto
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDto
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDto
     */
    'shape_id': string;
}
/**
 * 
 * @export
 * @interface ShapeTagDtoRequest
 */
export interface ShapeTagDtoRequest {
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDtoRequest
     */
    'rotate': number;
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDtoRequest
     */
    'x': number;
    /**
     * 
     * @type {number}
     * @memberof ShapeTagDtoRequest
     */
    'y': number;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDtoRequest
     */
    'label': string;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDtoRequest
     */
    'color'?: string | null;
    /**
     * 
     * @type {string}
     * @memberof ShapeTagDtoRequest
     */
    'shape_id': string;
}
/**
 * 
 * @export
 * @enum {string}
 */

export const ShapeType = {
    Rectangle: 'RECTANGLE',
    Line: 'LINE',
    Polygon: 'POLYGON'
} as const;

export type ShapeType = typeof ShapeType[keyof typeof ShapeType];


/**
 * 
 * @export
 * @interface UserDto
 */
export interface UserDto {
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'id': string | null;
    /**
     * 
     * @type {string}
     * @memberof UserDto
     */
    'login': string;
    /**
     * 
     * @type {AuthType}
     * @memberof UserDto
     */
    'auth_type': AuthType;
}


/**
 * 
 * @export
 * @interface ValidationError
 */
export interface ValidationError {
    /**
     * 
     * @type {Array<ValidationErrorLocInner>}
     * @memberof ValidationError
     */
    'loc': Array<ValidationErrorLocInner>;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'msg': string;
    /**
     * 
     * @type {string}
     * @memberof ValidationError
     */
    'type': string;
}
/**
 * 
 * @export
 * @interface ValidationErrorLocInner
 */
export interface ValidationErrorLocInner {
}

/**
 * ComponentsApi - axios parameter creator
 * @export
 */
export const ComponentsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete By External Id
         * @param {string} externalId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByExternalIdApiComponentsExternalIdExternalIdDelete: async (externalId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('deleteByExternalIdApiComponentsExternalIdExternalIdDelete', 'externalId', externalId)
            const localVarPath = `/api/components/external_id/{external_id}`
                .replace(`{${"external_id"}}`, encodeURIComponent(String(externalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Components
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllComponentsApiComponentsGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/components/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ComponentsApi - functional programming interface
 * @export
 */
export const ComponentsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ComponentsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete By External Id
         * @param {string} externalId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByExternalIdApiComponentsExternalIdExternalIdDelete(externalId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByExternalIdApiComponentsExternalIdExternalIdDelete(externalId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComponentsApi.deleteByExternalIdApiComponentsExternalIdExternalIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Components
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllComponentsApiComponentsGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ComponentResponse>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllComponentsApiComponentsGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ComponentsApi.getAllComponentsApiComponentsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ComponentsApi - factory interface
 * @export
 */
export const ComponentsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ComponentsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete By External Id
         * @param {string} externalId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByExternalIdApiComponentsExternalIdExternalIdDelete(externalId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteByExternalIdApiComponentsExternalIdExternalIdDelete(externalId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Components
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllComponentsApiComponentsGet(tenantId?: string, options?: any): AxiosPromise<Array<ComponentResponse>> {
            return localVarFp.getAllComponentsApiComponentsGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ComponentsApi - object-oriented interface
 * @export
 * @class ComponentsApi
 * @extends {BaseAPI}
 */
export class ComponentsApi extends BaseAPI {
    /**
     * 
     * @summary Delete By External Id
     * @param {string} externalId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentsApi
     */
    public deleteByExternalIdApiComponentsExternalIdExternalIdDelete(externalId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ComponentsApiFp(this.configuration).deleteByExternalIdApiComponentsExternalIdExternalIdDelete(externalId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Components
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ComponentsApi
     */
    public getAllComponentsApiComponentsGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return ComponentsApiFp(this.configuration).getAllComponentsApiComponentsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ConnectionApi - axios parameter creator
 * @export
 */
export const ConnectionApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Connection
         * @param {ConnectionDtoRequest} connectionDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnectionApiConnectionPost: async (connectionDtoRequest: ConnectionDtoRequest, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionDtoRequest' is not null or undefined
            assertParamExists('createConnectionApiConnectionPost', 'connectionDtoRequest', connectionDtoRequest)
            const localVarPath = `/api/connection/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectionDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Connection
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnectionApiConnectionConnectionIdDelete: async (connectionId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('deleteConnectionApiConnectionConnectionIdDelete', 'connectionId', connectionId)
            const localVarPath = `/api/connection/{connection_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Connections
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConnectionsApiConnectionGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/connection/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Connection
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionApiConnectionConnectionIdGet: async (connectionId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('getConnectionApiConnectionConnectionIdGet', 'connectionId', connectionId)
            const localVarPath = `/api/connection/{connection_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Connection
         * @param {string} connectionId 
         * @param {ConnectionDtoRequest} connectionDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionApiConnectionConnectionIdPut: async (connectionId: string, connectionDtoRequest: ConnectionDtoRequest, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('updateConnectionApiConnectionConnectionIdPut', 'connectionId', connectionId)
            // verify required parameter 'connectionDtoRequest' is not null or undefined
            assertParamExists('updateConnectionApiConnectionConnectionIdPut', 'connectionDtoRequest', connectionDtoRequest)
            const localVarPath = `/api/connection/{connection_id}`
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(connectionDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ConnectionApi - functional programming interface
 * @export
 */
export const ConnectionApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ConnectionApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Connection
         * @param {ConnectionDtoRequest} connectionDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createConnectionApiConnectionPost(connectionDtoRequest: ConnectionDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createConnectionApiConnectionPost(connectionDtoRequest, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.createConnectionApiConnectionPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Connection
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteConnectionApiConnectionConnectionIdDelete(connectionId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteConnectionApiConnectionConnectionIdDelete(connectionId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.deleteConnectionApiConnectionConnectionIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Connections
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllConnectionsApiConnectionGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ConnectionDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllConnectionsApiConnectionGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.getAllConnectionsApiConnectionGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Connection
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getConnectionApiConnectionConnectionIdGet(connectionId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getConnectionApiConnectionConnectionIdGet(connectionId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.getConnectionApiConnectionConnectionIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Connection
         * @param {string} connectionId 
         * @param {ConnectionDtoRequest} connectionDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateConnectionApiConnectionConnectionIdPut(connectionId: string, connectionDtoRequest: ConnectionDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ConnectionDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateConnectionApiConnectionConnectionIdPut(connectionId, connectionDtoRequest, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ConnectionApi.updateConnectionApiConnectionConnectionIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ConnectionApi - factory interface
 * @export
 */
export const ConnectionApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ConnectionApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Connection
         * @param {ConnectionDtoRequest} connectionDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createConnectionApiConnectionPost(connectionDtoRequest: ConnectionDtoRequest, tenantId?: string, options?: any): AxiosPromise<ConnectionDto> {
            return localVarFp.createConnectionApiConnectionPost(connectionDtoRequest, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Connection
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteConnectionApiConnectionConnectionIdDelete(connectionId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteConnectionApiConnectionConnectionIdDelete(connectionId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Connections
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllConnectionsApiConnectionGet(tenantId?: string, options?: any): AxiosPromise<Array<ConnectionDto>> {
            return localVarFp.getAllConnectionsApiConnectionGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Connection
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getConnectionApiConnectionConnectionIdGet(connectionId: string, tenantId?: string, options?: any): AxiosPromise<ConnectionDto> {
            return localVarFp.getConnectionApiConnectionConnectionIdGet(connectionId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Connection
         * @param {string} connectionId 
         * @param {ConnectionDtoRequest} connectionDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateConnectionApiConnectionConnectionIdPut(connectionId: string, connectionDtoRequest: ConnectionDtoRequest, tenantId?: string, options?: any): AxiosPromise<ConnectionDto> {
            return localVarFp.updateConnectionApiConnectionConnectionIdPut(connectionId, connectionDtoRequest, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ConnectionApi - object-oriented interface
 * @export
 * @class ConnectionApi
 * @extends {BaseAPI}
 */
export class ConnectionApi extends BaseAPI {
    /**
     * 
     * @summary Create Connection
     * @param {ConnectionDtoRequest} connectionDtoRequest 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public createConnectionApiConnectionPost(connectionDtoRequest: ConnectionDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).createConnectionApiConnectionPost(connectionDtoRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Connection
     * @param {string} connectionId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public deleteConnectionApiConnectionConnectionIdDelete(connectionId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).deleteConnectionApiConnectionConnectionIdDelete(connectionId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Connections
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public getAllConnectionsApiConnectionGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).getAllConnectionsApiConnectionGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Connection
     * @param {string} connectionId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public getConnectionApiConnectionConnectionIdGet(connectionId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).getConnectionApiConnectionConnectionIdGet(connectionId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Connection
     * @param {string} connectionId 
     * @param {ConnectionDtoRequest} connectionDtoRequest 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ConnectionApi
     */
    public updateConnectionApiConnectionConnectionIdPut(connectionId: string, connectionDtoRequest: ConnectionDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ConnectionApiFp(this.configuration).updateConnectionApiConnectionConnectionIdPut(connectionId, connectionDtoRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * CorrosionLoopsApi - axios parameter creator
 * @export
 */
export const CorrosionLoopsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get All Corrosion Loops
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCorrosionLoopsApiCorrosionLoopsGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/corrosion-loops/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Corrosion Loop
         * @param {string} externalId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorrosionLoopApiCorrosionLoopsExternalIdExternalIdDelete: async (externalId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('getCorrosionLoopApiCorrosionLoopsExternalIdExternalIdDelete', 'externalId', externalId)
            const localVarPath = `/api/corrosion-loops/external_id/{external_id}`
                .replace(`{${"external_id"}}`, encodeURIComponent(String(externalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CorrosionLoopsApi - functional programming interface
 * @export
 */
export const CorrosionLoopsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CorrosionLoopsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get All Corrosion Loops
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllCorrosionLoopsApiCorrosionLoopsGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<CorrosionLoopDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllCorrosionLoopsApiCorrosionLoopsGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CorrosionLoopsApi.getAllCorrosionLoopsApiCorrosionLoopsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Corrosion Loop
         * @param {string} externalId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCorrosionLoopApiCorrosionLoopsExternalIdExternalIdDelete(externalId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCorrosionLoopApiCorrosionLoopsExternalIdExternalIdDelete(externalId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['CorrosionLoopsApi.getCorrosionLoopApiCorrosionLoopsExternalIdExternalIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * CorrosionLoopsApi - factory interface
 * @export
 */
export const CorrosionLoopsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CorrosionLoopsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get All Corrosion Loops
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllCorrosionLoopsApiCorrosionLoopsGet(tenantId?: string, options?: any): AxiosPromise<Array<CorrosionLoopDto>> {
            return localVarFp.getAllCorrosionLoopsApiCorrosionLoopsGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Corrosion Loop
         * @param {string} externalId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCorrosionLoopApiCorrosionLoopsExternalIdExternalIdDelete(externalId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.getCorrosionLoopApiCorrosionLoopsExternalIdExternalIdDelete(externalId, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CorrosionLoopsApi - object-oriented interface
 * @export
 * @class CorrosionLoopsApi
 * @extends {BaseAPI}
 */
export class CorrosionLoopsApi extends BaseAPI {
    /**
     * 
     * @summary Get All Corrosion Loops
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrosionLoopsApi
     */
    public getAllCorrosionLoopsApiCorrosionLoopsGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return CorrosionLoopsApiFp(this.configuration).getAllCorrosionLoopsApiCorrosionLoopsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Corrosion Loop
     * @param {string} externalId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CorrosionLoopsApi
     */
    public getCorrosionLoopApiCorrosionLoopsExternalIdExternalIdDelete(externalId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return CorrosionLoopsApiFp(this.configuration).getCorrosionLoopApiCorrosionLoopsExternalIdExternalIdDelete(externalId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * DcApi - axios parameter creator
 * @export
 */
export const DcApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get All Dc
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDcApiDcGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/dc/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * DcApi - functional programming interface
 * @export
 */
export const DcApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = DcApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get All Dc
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllDcApiDcGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<DiagramComponent>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllDcApiDcGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['DcApi.getAllDcApiDcGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * DcApi - factory interface
 * @export
 */
export const DcApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = DcApiFp(configuration)
    return {
        /**
         * 
         * @summary Get All Dc
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllDcApiDcGet(tenantId?: string, options?: any): AxiosPromise<Array<DiagramComponent>> {
            return localVarFp.getAllDcApiDcGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * DcApi - object-oriented interface
 * @export
 * @class DcApi
 * @extends {BaseAPI}
 */
export class DcApi extends BaseAPI {
    /**
     * 
     * @summary Get All Dc
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof DcApi
     */
    public getAllDcApiDcGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return DcApiFp(this.configuration).getAllDcApiDcGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * EquipmentApi - axios parameter creator
 * @export
 */
export const EquipmentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete By External Id
         * @param {string} externalId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByExternalIdApiEquipmentExternalIdExternalIdDelete: async (externalId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('deleteByExternalIdApiEquipmentExternalIdExternalIdDelete', 'externalId', externalId)
            const localVarPath = `/api/equipment/external_id/{external_id}`
                .replace(`{${"external_id"}}`, encodeURIComponent(String(externalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Equipment
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEquipmentApiEquipmentGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/equipment/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * EquipmentApi - functional programming interface
 * @export
 */
export const EquipmentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = EquipmentApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete By External Id
         * @param {string} externalId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByExternalIdApiEquipmentExternalIdExternalIdDelete(externalId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByExternalIdApiEquipmentExternalIdExternalIdDelete(externalId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EquipmentApi.deleteByExternalIdApiEquipmentExternalIdExternalIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Equipment
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllEquipmentApiEquipmentGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<EquipmentDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllEquipmentApiEquipmentGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['EquipmentApi.getAllEquipmentApiEquipmentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * EquipmentApi - factory interface
 * @export
 */
export const EquipmentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = EquipmentApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete By External Id
         * @param {string} externalId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByExternalIdApiEquipmentExternalIdExternalIdDelete(externalId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteByExternalIdApiEquipmentExternalIdExternalIdDelete(externalId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Equipment
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllEquipmentApiEquipmentGet(tenantId?: string, options?: any): AxiosPromise<Array<EquipmentDto>> {
            return localVarFp.getAllEquipmentApiEquipmentGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * EquipmentApi - object-oriented interface
 * @export
 * @class EquipmentApi
 * @extends {BaseAPI}
 */
export class EquipmentApi extends BaseAPI {
    /**
     * 
     * @summary Delete By External Id
     * @param {string} externalId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public deleteByExternalIdApiEquipmentExternalIdExternalIdDelete(externalId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).deleteByExternalIdApiEquipmentExternalIdExternalIdDelete(externalId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Equipment
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof EquipmentApi
     */
    public getAllEquipmentApiEquipmentGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return EquipmentApiFp(this.configuration).getAllEquipmentApiEquipmentGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * FileApi - axios parameter creator
 * @export
 */
export const FileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileApiFileFileIdDelete: async (fileId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteFileApiFileFileIdDelete', 'fileId', fileId)
            const localVarPath = `/api/file/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFilesApiFileGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/file/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Content
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentApiFileFileIdContentGet: async (fileId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getContentApiFileFileIdContentGet', 'fileId', fileId)
            const localVarPath = `/api/file/{file_id}/content`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileApiFileFileIdGet: async (fileId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getFileApiFileFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/file/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Chunk
         * @param {number} chunkNumber 
         * @param {string} uploadId 
         * @param {string} fileKey 
         * @param {File} chunk 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadChunkApiFileUploadChunkPost: async (chunkNumber: number, uploadId: string, fileKey: string, chunk: File, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'chunkNumber' is not null or undefined
            assertParamExists('uploadChunkApiFileUploadChunkPost', 'chunkNumber', chunkNumber)
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('uploadChunkApiFileUploadChunkPost', 'uploadId', uploadId)
            // verify required parameter 'fileKey' is not null or undefined
            assertParamExists('uploadChunkApiFileUploadChunkPost', 'fileKey', fileKey)
            // verify required parameter 'chunk' is not null or undefined
            assertParamExists('uploadChunkApiFileUploadChunkPost', 'chunk', chunk)
            const localVarPath = `/api/file/upload/chunk`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();

            // authentication APIKeyCookie required

            if (chunkNumber !== undefined) {
                localVarQueryParameter['chunk_number'] = chunkNumber;
            }

            if (uploadId !== undefined) {
                localVarQueryParameter['upload_id'] = uploadId;
            }

            if (fileKey !== undefined) {
                localVarQueryParameter['file_key'] = fileKey;
            }

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


            if (chunk !== undefined) { 
                localVarFormParams.append('chunk', chunk as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Finish
         * @param {string} uploadId 
         * @param {string} fileKey 
         * @param {string} filename 
         * @param {Array<ChunkUploadData>} chunkUploadData 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFinishApiFileUploadFinishPost: async (uploadId: string, fileKey: string, filename: string, chunkUploadData: Array<ChunkUploadData>, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'uploadId' is not null or undefined
            assertParamExists('uploadFinishApiFileUploadFinishPost', 'uploadId', uploadId)
            // verify required parameter 'fileKey' is not null or undefined
            assertParamExists('uploadFinishApiFileUploadFinishPost', 'fileKey', fileKey)
            // verify required parameter 'filename' is not null or undefined
            assertParamExists('uploadFinishApiFileUploadFinishPost', 'filename', filename)
            // verify required parameter 'chunkUploadData' is not null or undefined
            assertParamExists('uploadFinishApiFileUploadFinishPost', 'chunkUploadData', chunkUploadData)
            const localVarPath = `/api/file/upload/finish`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (uploadId !== undefined) {
                localVarQueryParameter['upload_id'] = uploadId;
            }

            if (fileKey !== undefined) {
                localVarQueryParameter['file_key'] = fileKey;
            }

            if (filename !== undefined) {
                localVarQueryParameter['filename'] = filename;
            }

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(chunkUploadData, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Upload Start
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStartApiFileUploadStartPost: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/file/upload/start`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * FileApi - functional programming interface
 * @export
 */
export const FileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = FileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteFileApiFileFileIdDelete(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteFileApiFileFileIdDelete(fileId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.deleteFileApiFileFileIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllFilesApiFileGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<FileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllFilesApiFileGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.getAllFilesApiFileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Content
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentApiFileFileIdContentGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentApiFileFileIdContentGet(fileId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.getContentApiFileFileIdContentGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFileApiFileFileIdGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFileApiFileFileIdGet(fileId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.getFileApiFileFileIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload Chunk
         * @param {number} chunkNumber 
         * @param {string} uploadId 
         * @param {string} fileKey 
         * @param {File} chunk 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadChunkApiFileUploadChunkPost(chunkNumber: number, uploadId: string, fileKey: string, chunk: File, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ChunkUploadData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadChunkApiFileUploadChunkPost(chunkNumber, uploadId, fileKey, chunk, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.uploadChunkApiFileUploadChunkPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload Finish
         * @param {string} uploadId 
         * @param {string} fileKey 
         * @param {string} filename 
         * @param {Array<ChunkUploadData>} chunkUploadData 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadFinishApiFileUploadFinishPost(uploadId: string, fileKey: string, filename: string, chunkUploadData: Array<ChunkUploadData>, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<FileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadFinishApiFileUploadFinishPost(uploadId, fileKey, filename, chunkUploadData, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.uploadFinishApiFileUploadFinishPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Upload Start
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadStartApiFileUploadStartPost(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<MultipartUploadData>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadStartApiFileUploadStartPost(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['FileApi.uploadStartApiFileUploadStartPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * FileApi - factory interface
 * @export
 */
export const FileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = FileApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteFileApiFileFileIdDelete(fileId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteFileApiFileFileIdDelete(fileId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllFilesApiFileGet(tenantId?: string, options?: any): AxiosPromise<Array<FileDto>> {
            return localVarFp.getAllFilesApiFileGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Content
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentApiFileFileIdContentGet(fileId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.getContentApiFileFileIdContentGet(fileId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFileApiFileFileIdGet(fileId: string, tenantId?: string, options?: any): AxiosPromise<FileDto> {
            return localVarFp.getFileApiFileFileIdGet(fileId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Chunk
         * @param {number} chunkNumber 
         * @param {string} uploadId 
         * @param {string} fileKey 
         * @param {File} chunk 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadChunkApiFileUploadChunkPost(chunkNumber: number, uploadId: string, fileKey: string, chunk: File, tenantId?: string, options?: any): AxiosPromise<ChunkUploadData> {
            return localVarFp.uploadChunkApiFileUploadChunkPost(chunkNumber, uploadId, fileKey, chunk, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Finish
         * @param {string} uploadId 
         * @param {string} fileKey 
         * @param {string} filename 
         * @param {Array<ChunkUploadData>} chunkUploadData 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadFinishApiFileUploadFinishPost(uploadId: string, fileKey: string, filename: string, chunkUploadData: Array<ChunkUploadData>, tenantId?: string, options?: any): AxiosPromise<FileDto> {
            return localVarFp.uploadFinishApiFileUploadFinishPost(uploadId, fileKey, filename, chunkUploadData, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Upload Start
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadStartApiFileUploadStartPost(tenantId?: string, options?: any): AxiosPromise<MultipartUploadData> {
            return localVarFp.uploadStartApiFileUploadStartPost(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * FileApi - object-oriented interface
 * @export
 * @class FileApi
 * @extends {BaseAPI}
 */
export class FileApi extends BaseAPI {
    /**
     * 
     * @summary Delete File
     * @param {string} fileId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public deleteFileApiFileFileIdDelete(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).deleteFileApiFileFileIdDelete(fileId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Files
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getAllFilesApiFileGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).getAllFilesApiFileGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Content
     * @param {string} fileId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getContentApiFileFileIdContentGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).getContentApiFileFileIdContentGet(fileId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get File
     * @param {string} fileId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public getFileApiFileFileIdGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).getFileApiFileFileIdGet(fileId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Chunk
     * @param {number} chunkNumber 
     * @param {string} uploadId 
     * @param {string} fileKey 
     * @param {File} chunk 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public uploadChunkApiFileUploadChunkPost(chunkNumber: number, uploadId: string, fileKey: string, chunk: File, tenantId?: string, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).uploadChunkApiFileUploadChunkPost(chunkNumber, uploadId, fileKey, chunk, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Finish
     * @param {string} uploadId 
     * @param {string} fileKey 
     * @param {string} filename 
     * @param {Array<ChunkUploadData>} chunkUploadData 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public uploadFinishApiFileUploadFinishPost(uploadId: string, fileKey: string, filename: string, chunkUploadData: Array<ChunkUploadData>, tenantId?: string, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).uploadFinishApiFileUploadFinishPost(uploadId, fileKey, filename, chunkUploadData, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Upload Start
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof FileApi
     */
    public uploadStartApiFileUploadStartPost(tenantId?: string, options?: RawAxiosRequestConfig) {
        return FileApiFp(this.configuration).uploadStartApiFileUploadStartPost(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * HierarchyItemsApi - axios parameter creator
 * @export
 */
export const HierarchyItemsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete By External Id
         * @param {string} externalId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByExternalIdApiHierarchyItemsExternalIdExternalIdDelete: async (externalId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'externalId' is not null or undefined
            assertParamExists('deleteByExternalIdApiHierarchyItemsExternalIdExternalIdDelete', 'externalId', externalId)
            const localVarPath = `/api/hierarchy-items/external_id/{external_id}`
                .replace(`{${"external_id"}}`, encodeURIComponent(String(externalId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Hierarchy Items
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHierarchyItemsApiHierarchyItemsGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/hierarchy-items/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * HierarchyItemsApi - functional programming interface
 * @export
 */
export const HierarchyItemsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = HierarchyItemsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete By External Id
         * @param {string} externalId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteByExternalIdApiHierarchyItemsExternalIdExternalIdDelete(externalId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteByExternalIdApiHierarchyItemsExternalIdExternalIdDelete(externalId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HierarchyItemsApi.deleteByExternalIdApiHierarchyItemsExternalIdExternalIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Hierarchy Items
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllHierarchyItemsApiHierarchyItemsGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<HierarchyItemDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllHierarchyItemsApiHierarchyItemsGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['HierarchyItemsApi.getAllHierarchyItemsApiHierarchyItemsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * HierarchyItemsApi - factory interface
 * @export
 */
export const HierarchyItemsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = HierarchyItemsApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete By External Id
         * @param {string} externalId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteByExternalIdApiHierarchyItemsExternalIdExternalIdDelete(externalId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteByExternalIdApiHierarchyItemsExternalIdExternalIdDelete(externalId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Hierarchy Items
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllHierarchyItemsApiHierarchyItemsGet(tenantId?: string, options?: any): AxiosPromise<Array<HierarchyItemDto>> {
            return localVarFp.getAllHierarchyItemsApiHierarchyItemsGet(tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * HierarchyItemsApi - object-oriented interface
 * @export
 * @class HierarchyItemsApi
 * @extends {BaseAPI}
 */
export class HierarchyItemsApi extends BaseAPI {
    /**
     * 
     * @summary Delete By External Id
     * @param {string} externalId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HierarchyItemsApi
     */
    public deleteByExternalIdApiHierarchyItemsExternalIdExternalIdDelete(externalId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return HierarchyItemsApiFp(this.configuration).deleteByExternalIdApiHierarchyItemsExternalIdExternalIdDelete(externalId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Hierarchy Items
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof HierarchyItemsApi
     */
    public getAllHierarchyItemsApiHierarchyItemsGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return HierarchyItemsApiFp(this.configuration).getAllHierarchyItemsApiHierarchyItemsGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ImageDrawingFileApi - axios parameter creator
 * @export
 */
export const ImageDrawingFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Image Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageDrawingFileApiImageDrawingFileFileIdDelete: async (fileId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deleteImageDrawingFileApiImageDrawingFileFileIdDelete', 'fileId', fileId)
            const localVarPath = `/api/imageDrawingFile/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Image Drawing Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImageDrawingFilesApiImageDrawingFileGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/imageDrawingFile/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Image By Drawing And Number
         * @param {string} pdfId 
         * @param {number} imageNumber 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet: async (pdfId: string, imageNumber: number, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pdfId' is not null or undefined
            assertParamExists('getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet', 'pdfId', pdfId)
            // verify required parameter 'imageNumber' is not null or undefined
            assertParamExists('getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet', 'imageNumber', imageNumber)
            const localVarPath = `/api/imageDrawingFile/by-pdf-and-number/{pdf_id}`
                .replace(`{${"pdf_id"}}`, encodeURIComponent(String(pdfId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (imageNumber !== undefined) {
                localVarQueryParameter['image_number'] = imageNumber;
            }

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Image Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageDrawingFileApiImageDrawingFileFileIdGet: async (fileId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getImageDrawingFileApiImageDrawingFileFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/imageDrawingFile/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Image Preview
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagePreviewApiImageDrawingFileFileIdPreviewGet: async (fileId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getImagePreviewApiImageDrawingFileFileIdPreviewGet', 'fileId', fileId)
            const localVarPath = `/api/imageDrawingFile/{file_id}/preview`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Images By Drawing
         * @param {string} pdfId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet: async (pdfId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'pdfId' is not null or undefined
            assertParamExists('getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet', 'pdfId', pdfId)
            const localVarPath = `/api/imageDrawingFile/by-drawing/{pdf_id}`
                .replace(`{${"pdf_id"}}`, encodeURIComponent(String(pdfId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ImageDrawingFileApi - functional programming interface
 * @export
 */
export const ImageDrawingFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ImageDrawingFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Image Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteImageDrawingFileApiImageDrawingFileFileIdDelete(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteImageDrawingFileApiImageDrawingFileFileIdDelete(fileId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImageDrawingFileApi.deleteImageDrawingFileApiImageDrawingFileFileIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Image Drawing Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllImageDrawingFilesApiImageDrawingFileGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageDrawingFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllImageDrawingFilesApiImageDrawingFileGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImageDrawingFileApi.getAllImageDrawingFilesApiImageDrawingFileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Image By Drawing And Number
         * @param {string} pdfId 
         * @param {number} imageNumber 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet(pdfId: string, imageNumber: number, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageDrawingFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet(pdfId, imageNumber, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImageDrawingFileApi.getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Image Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImageDrawingFileApiImageDrawingFileFileIdGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ImageDrawingFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImageDrawingFileApiImageDrawingFileFileIdGet(fileId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImageDrawingFileApi.getImageDrawingFileApiImageDrawingFileFileIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Image Preview
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImagePreviewApiImageDrawingFileFileIdPreviewGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImagePreviewApiImageDrawingFileFileIdPreviewGet(fileId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImageDrawingFileApi.getImagePreviewApiImageDrawingFileFileIdPreviewGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Images By Drawing
         * @param {string} pdfId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet(pdfId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ImageDrawingFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet(pdfId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ImageDrawingFileApi.getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ImageDrawingFileApi - factory interface
 * @export
 */
export const ImageDrawingFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ImageDrawingFileApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Image Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteImageDrawingFileApiImageDrawingFileFileIdDelete(fileId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteImageDrawingFileApiImageDrawingFileFileIdDelete(fileId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Image Drawing Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllImageDrawingFilesApiImageDrawingFileGet(tenantId?: string, options?: any): AxiosPromise<Array<ImageDrawingFileDto>> {
            return localVarFp.getAllImageDrawingFilesApiImageDrawingFileGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Image By Drawing And Number
         * @param {string} pdfId 
         * @param {number} imageNumber 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet(pdfId: string, imageNumber: number, tenantId?: string, options?: any): AxiosPromise<ImageDrawingFileDto> {
            return localVarFp.getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet(pdfId, imageNumber, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Image Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImageDrawingFileApiImageDrawingFileFileIdGet(fileId: string, tenantId?: string, options?: any): AxiosPromise<ImageDrawingFileDto> {
            return localVarFp.getImageDrawingFileApiImageDrawingFileFileIdGet(fileId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Image Preview
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagePreviewApiImageDrawingFileFileIdPreviewGet(fileId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.getImagePreviewApiImageDrawingFileFileIdPreviewGet(fileId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Images By Drawing
         * @param {string} pdfId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet(pdfId: string, tenantId?: string, options?: any): AxiosPromise<Array<ImageDrawingFileDto>> {
            return localVarFp.getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet(pdfId, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ImageDrawingFileApi - object-oriented interface
 * @export
 * @class ImageDrawingFileApi
 * @extends {BaseAPI}
 */
export class ImageDrawingFileApi extends BaseAPI {
    /**
     * 
     * @summary Delete Image Drawing File
     * @param {string} fileId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageDrawingFileApi
     */
    public deleteImageDrawingFileApiImageDrawingFileFileIdDelete(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ImageDrawingFileApiFp(this.configuration).deleteImageDrawingFileApiImageDrawingFileFileIdDelete(fileId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Image Drawing Files
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageDrawingFileApi
     */
    public getAllImageDrawingFilesApiImageDrawingFileGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return ImageDrawingFileApiFp(this.configuration).getAllImageDrawingFilesApiImageDrawingFileGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Image By Drawing And Number
     * @param {string} pdfId 
     * @param {number} imageNumber 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageDrawingFileApi
     */
    public getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet(pdfId: string, imageNumber: number, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ImageDrawingFileApiFp(this.configuration).getImageByDrawingAndNumberApiImageDrawingFileByPdfAndNumberPdfIdGet(pdfId, imageNumber, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Image Drawing File
     * @param {string} fileId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageDrawingFileApi
     */
    public getImageDrawingFileApiImageDrawingFileFileIdGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ImageDrawingFileApiFp(this.configuration).getImageDrawingFileApiImageDrawingFileFileIdGet(fileId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Image Preview
     * @param {string} fileId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageDrawingFileApi
     */
    public getImagePreviewApiImageDrawingFileFileIdPreviewGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ImageDrawingFileApiFp(this.configuration).getImagePreviewApiImageDrawingFileFileIdPreviewGet(fileId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Images By Drawing
     * @param {string} pdfId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ImageDrawingFileApi
     */
    public getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet(pdfId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ImageDrawingFileApiFp(this.configuration).getImagesByDrawingApiImageDrawingFileByDrawingPdfIdGet(pdfId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * IntegrationApi - axios parameter creator
 * @export
 */
export const IntegrationApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Синхронизация данных (добавление и изменение CorrosionLoop и его компонентов)
         * @summary Sync Data
         * @param {Array<CorrosionLoopDtoRequestIntegration>} corrosionLoopDtoRequestIntegration 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDataApiIntegrationCorrosionLoopPost: async (corrosionLoopDtoRequestIntegration: Array<CorrosionLoopDtoRequestIntegration>, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'corrosionLoopDtoRequestIntegration' is not null or undefined
            assertParamExists('syncDataApiIntegrationCorrosionLoopPost', 'corrosionLoopDtoRequestIntegration', corrosionLoopDtoRequestIntegration)
            const localVarPath = `/api/integration/corrosion_loop`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(corrosionLoopDtoRequestIntegration, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Синхронизация данных (добавление и изменение Components, Equipment, HierarchyItem) через массив объектов IntegrationImportDtoRequest. IntegrationImportDtoRequest представляет из себя связку из не обязательного поля hierarchy_item первого уровня (остальные на данный момент не поддерживаются), equipment который принадлежит hierarchy_item (если он передан, если не передан то equipment добавляется без родительского hierarchyItem) и списка components которые принадлежат equipment
         * @summary Sync Data
         * @param {Array<IntegrationImportDtoRequest>} integrationImportDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDataApiIntegrationPost: async (integrationImportDtoRequest: Array<IntegrationImportDtoRequest>, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'integrationImportDtoRequest' is not null or undefined
            assertParamExists('syncDataApiIntegrationPost', 'integrationImportDtoRequest', integrationImportDtoRequest)
            const localVarPath = `/api/integration/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(integrationImportDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * IntegrationApi - functional programming interface
 * @export
 */
export const IntegrationApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = IntegrationApiAxiosParamCreator(configuration)
    return {
        /**
         * Синхронизация данных (добавление и изменение CorrosionLoop и его компонентов)
         * @summary Sync Data
         * @param {Array<CorrosionLoopDtoRequestIntegration>} corrosionLoopDtoRequestIntegration 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncDataApiIntegrationCorrosionLoopPost(corrosionLoopDtoRequestIntegration: Array<CorrosionLoopDtoRequestIntegration>, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncDataApiIntegrationCorrosionLoopPost(corrosionLoopDtoRequestIntegration, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApi.syncDataApiIntegrationCorrosionLoopPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Синхронизация данных (добавление и изменение Components, Equipment, HierarchyItem) через массив объектов IntegrationImportDtoRequest. IntegrationImportDtoRequest представляет из себя связку из не обязательного поля hierarchy_item первого уровня (остальные на данный момент не поддерживаются), equipment который принадлежит hierarchy_item (если он передан, если не передан то equipment добавляется без родительского hierarchyItem) и списка components которые принадлежат equipment
         * @summary Sync Data
         * @param {Array<IntegrationImportDtoRequest>} integrationImportDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async syncDataApiIntegrationPost(integrationImportDtoRequest: Array<IntegrationImportDtoRequest>, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<boolean>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.syncDataApiIntegrationPost(integrationImportDtoRequest, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['IntegrationApi.syncDataApiIntegrationPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * IntegrationApi - factory interface
 * @export
 */
export const IntegrationApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = IntegrationApiFp(configuration)
    return {
        /**
         * Синхронизация данных (добавление и изменение CorrosionLoop и его компонентов)
         * @summary Sync Data
         * @param {Array<CorrosionLoopDtoRequestIntegration>} corrosionLoopDtoRequestIntegration 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDataApiIntegrationCorrosionLoopPost(corrosionLoopDtoRequestIntegration: Array<CorrosionLoopDtoRequestIntegration>, tenantId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.syncDataApiIntegrationCorrosionLoopPost(corrosionLoopDtoRequestIntegration, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * Синхронизация данных (добавление и изменение Components, Equipment, HierarchyItem) через массив объектов IntegrationImportDtoRequest. IntegrationImportDtoRequest представляет из себя связку из не обязательного поля hierarchy_item первого уровня (остальные на данный момент не поддерживаются), equipment который принадлежит hierarchy_item (если он передан, если не передан то equipment добавляется без родительского hierarchyItem) и списка components которые принадлежат equipment
         * @summary Sync Data
         * @param {Array<IntegrationImportDtoRequest>} integrationImportDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        syncDataApiIntegrationPost(integrationImportDtoRequest: Array<IntegrationImportDtoRequest>, tenantId?: string, options?: any): AxiosPromise<boolean> {
            return localVarFp.syncDataApiIntegrationPost(integrationImportDtoRequest, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * IntegrationApi - object-oriented interface
 * @export
 * @class IntegrationApi
 * @extends {BaseAPI}
 */
export class IntegrationApi extends BaseAPI {
    /**
     * Синхронизация данных (добавление и изменение CorrosionLoop и его компонентов)
     * @summary Sync Data
     * @param {Array<CorrosionLoopDtoRequestIntegration>} corrosionLoopDtoRequestIntegration 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public syncDataApiIntegrationCorrosionLoopPost(corrosionLoopDtoRequestIntegration: Array<CorrosionLoopDtoRequestIntegration>, tenantId?: string, options?: RawAxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).syncDataApiIntegrationCorrosionLoopPost(corrosionLoopDtoRequestIntegration, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Синхронизация данных (добавление и изменение Components, Equipment, HierarchyItem) через массив объектов IntegrationImportDtoRequest. IntegrationImportDtoRequest представляет из себя связку из не обязательного поля hierarchy_item первого уровня (остальные на данный момент не поддерживаются), equipment который принадлежит hierarchy_item (если он передан, если не передан то equipment добавляется без родительского hierarchyItem) и списка components которые принадлежат equipment
     * @summary Sync Data
     * @param {Array<IntegrationImportDtoRequest>} integrationImportDtoRequest 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof IntegrationApi
     */
    public syncDataApiIntegrationPost(integrationImportDtoRequest: Array<IntegrationImportDtoRequest>, tenantId?: string, options?: RawAxiosRequestConfig) {
        return IntegrationApiFp(this.configuration).syncDataApiIntegrationPost(integrationImportDtoRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * LocalizationsApi - axios parameter creator
 * @export
 */
export const LocalizationsApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Localization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalizationApiLocalizationsGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/localizations/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * LocalizationsApi - functional programming interface
 * @export
 */
export const LocalizationsApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = LocalizationsApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Localization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getLocalizationApiLocalizationsGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getLocalizationApiLocalizationsGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['LocalizationsApi.getLocalizationApiLocalizationsGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * LocalizationsApi - factory interface
 * @export
 */
export const LocalizationsApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = LocalizationsApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Localization
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getLocalizationApiLocalizationsGet(options?: any): AxiosPromise<any> {
            return localVarFp.getLocalizationApiLocalizationsGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * LocalizationsApi - object-oriented interface
 * @export
 * @class LocalizationsApi
 * @extends {BaseAPI}
 */
export class LocalizationsApi extends BaseAPI {
    /**
     * 
     * @summary Get Localization
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof LocalizationsApi
     */
    public getLocalizationApiLocalizationsGet(options?: RawAxiosRequestConfig) {
        return LocalizationsApiFp(this.configuration).getLocalizationApiLocalizationsGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * PdfDrawingFileApi - axios parameter creator
 * @export
 */
export const PdfDrawingFileApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Delete Pdf Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePdfDrawingFileApiPdfDrawingFileFileIdDelete: async (fileId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('deletePdfDrawingFileApiPdfDrawingFileFileIdDelete', 'fileId', fileId)
            const localVarPath = `/api/pdfDrawingFile/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Pdf Drawing Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPdfDrawingFilesApiPdfDrawingFileGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/pdfDrawingFile/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Pdf Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfDrawingFileApiPdfDrawingFileFileIdGet: async (fileId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'fileId' is not null or undefined
            assertParamExists('getPdfDrawingFileApiPdfDrawingFileFileIdGet', 'fileId', fileId)
            const localVarPath = `/api/pdfDrawingFile/{file_id}`
                .replace(`{${"file_id"}}`, encodeURIComponent(String(fileId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * PdfDrawingFileApi - functional programming interface
 * @export
 */
export const PdfDrawingFileApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = PdfDrawingFileApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Delete Pdf Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deletePdfDrawingFileApiPdfDrawingFileFileIdDelete(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deletePdfDrawingFileApiPdfDrawingFileFileIdDelete(fileId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PdfDrawingFileApi.deletePdfDrawingFileApiPdfDrawingFileFileIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Pdf Drawing Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllPdfDrawingFilesApiPdfDrawingFileGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<PdfDrawingFileDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllPdfDrawingFilesApiPdfDrawingFileGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PdfDrawingFileApi.getAllPdfDrawingFilesApiPdfDrawingFileGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Pdf Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getPdfDrawingFileApiPdfDrawingFileFileIdGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<PdfDrawingFileDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getPdfDrawingFileApiPdfDrawingFileFileIdGet(fileId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['PdfDrawingFileApi.getPdfDrawingFileApiPdfDrawingFileFileIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * PdfDrawingFileApi - factory interface
 * @export
 */
export const PdfDrawingFileApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = PdfDrawingFileApiFp(configuration)
    return {
        /**
         * 
         * @summary Delete Pdf Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deletePdfDrawingFileApiPdfDrawingFileFileIdDelete(fileId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deletePdfDrawingFileApiPdfDrawingFileFileIdDelete(fileId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Pdf Drawing Files
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllPdfDrawingFilesApiPdfDrawingFileGet(tenantId?: string, options?: any): AxiosPromise<Array<PdfDrawingFileDto>> {
            return localVarFp.getAllPdfDrawingFilesApiPdfDrawingFileGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Pdf Drawing File
         * @param {string} fileId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getPdfDrawingFileApiPdfDrawingFileFileIdGet(fileId: string, tenantId?: string, options?: any): AxiosPromise<PdfDrawingFileDto> {
            return localVarFp.getPdfDrawingFileApiPdfDrawingFileFileIdGet(fileId, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * PdfDrawingFileApi - object-oriented interface
 * @export
 * @class PdfDrawingFileApi
 * @extends {BaseAPI}
 */
export class PdfDrawingFileApi extends BaseAPI {
    /**
     * 
     * @summary Delete Pdf Drawing File
     * @param {string} fileId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfDrawingFileApi
     */
    public deletePdfDrawingFileApiPdfDrawingFileFileIdDelete(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return PdfDrawingFileApiFp(this.configuration).deletePdfDrawingFileApiPdfDrawingFileFileIdDelete(fileId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Pdf Drawing Files
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfDrawingFileApi
     */
    public getAllPdfDrawingFilesApiPdfDrawingFileGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return PdfDrawingFileApiFp(this.configuration).getAllPdfDrawingFilesApiPdfDrawingFileGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Pdf Drawing File
     * @param {string} fileId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof PdfDrawingFileApi
     */
    public getPdfDrawingFileApiPdfDrawingFileFileIdGet(fileId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return PdfDrawingFileApiFp(this.configuration).getPdfDrawingFileApiPdfDrawingFileFileIdGet(fileId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShapeApi - axios parameter creator
 * @export
 */
export const ShapeApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Shape
         * @param {ShapeDtoRequest} shapeDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShapeApiShapePost: async (shapeDtoRequest: ShapeDtoRequest, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeDtoRequest' is not null or undefined
            assertParamExists('createShapeApiShapePost', 'shapeDtoRequest', shapeDtoRequest)
            const localVarPath = `/api/shape/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shapeDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Shape
         * @param {string} shapeId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShapeApiShapeShapeIdDelete: async (shapeId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeId' is not null or undefined
            assertParamExists('deleteShapeApiShapeShapeIdDelete', 'shapeId', shapeId)
            const localVarPath = `/api/shape/{shape_id}`
                .replace(`{${"shape_id"}}`, encodeURIComponent(String(shapeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Shapes
         * @param {string} imageDrawingId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShapesApiShapeByImageDrawingImageDrawingIdGet: async (imageDrawingId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'imageDrawingId' is not null or undefined
            assertParamExists('getAllShapesApiShapeByImageDrawingImageDrawingIdGet', 'imageDrawingId', imageDrawingId)
            const localVarPath = `/api/shape/by-imageDrawing/{image_drawing_id}`
                .replace(`{${"image_drawing_id"}}`, encodeURIComponent(String(imageDrawingId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Shapes
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShapesApiShapeGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shape/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Shape
         * @param {string} shapeId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShapeApiShapeShapeIdGet: async (shapeId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeId' is not null or undefined
            assertParamExists('getShapeApiShapeShapeIdGet', 'shapeId', shapeId)
            const localVarPath = `/api/shape/{shape_id}`
                .replace(`{${"shape_id"}}`, encodeURIComponent(String(shapeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link Shape With Connection
         * @param {string} shapeId 
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost: async (shapeId: string, connectionId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeId' is not null or undefined
            assertParamExists('linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost', 'shapeId', shapeId)
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost', 'connectionId', connectionId)
            const localVarPath = `/api/shape/{shape_id}/attach/connection/{connection_id}`
                .replace(`{${"shape_id"}}`, encodeURIComponent(String(shapeId)))
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Link Shape With Diagram Component
         * @param {string} shapeId 
         * @param {string} diagramComponentId 
         * @param {DiagramComponentType} dcType 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost: async (shapeId: string, diagramComponentId: string, dcType: DiagramComponentType, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeId' is not null or undefined
            assertParamExists('linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost', 'shapeId', shapeId)
            // verify required parameter 'diagramComponentId' is not null or undefined
            assertParamExists('linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost', 'diagramComponentId', diagramComponentId)
            // verify required parameter 'dcType' is not null or undefined
            assertParamExists('linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost', 'dcType', dcType)
            const localVarPath = `/api/shape/{shape_id}/attach/diagram-component/{diagram_component_id}`
                .replace(`{${"shape_id"}}`, encodeURIComponent(String(shapeId)))
                .replace(`{${"diagram_component_id"}}`, encodeURIComponent(String(diagramComponentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (dcType !== undefined) {
                localVarQueryParameter['dc_type'] = dcType;
            }

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlink Shape From Connection
         * @param {string} shapeId 
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete: async (shapeId: string, connectionId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeId' is not null or undefined
            assertParamExists('unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete', 'shapeId', shapeId)
            // verify required parameter 'connectionId' is not null or undefined
            assertParamExists('unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete', 'connectionId', connectionId)
            const localVarPath = `/api/shape/{shape_id}/detach/connection/{connection_id}`
                .replace(`{${"shape_id"}}`, encodeURIComponent(String(shapeId)))
                .replace(`{${"connection_id"}}`, encodeURIComponent(String(connectionId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Unlink Shape From Diagram Component
         * @param {string} shapeId 
         * @param {string} diagramComponentId 
         * @param {DiagramComponentType} dcType 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete: async (shapeId: string, diagramComponentId: string, dcType: DiagramComponentType, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeId' is not null or undefined
            assertParamExists('unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete', 'shapeId', shapeId)
            // verify required parameter 'diagramComponentId' is not null or undefined
            assertParamExists('unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete', 'diagramComponentId', diagramComponentId)
            // verify required parameter 'dcType' is not null or undefined
            assertParamExists('unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete', 'dcType', dcType)
            const localVarPath = `/api/shape/{shape_id}/detach/diagram-component/{diagram_component_id}`
                .replace(`{${"shape_id"}}`, encodeURIComponent(String(shapeId)))
                .replace(`{${"diagram_component_id"}}`, encodeURIComponent(String(diagramComponentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (dcType !== undefined) {
                localVarQueryParameter['dc_type'] = dcType;
            }

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Shape
         * @param {string} shapeId 
         * @param {ShapeDtoRequest} shapeDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShapeApiShapeShapeIdPut: async (shapeId: string, shapeDtoRequest: ShapeDtoRequest, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeId' is not null or undefined
            assertParamExists('updateShapeApiShapeShapeIdPut', 'shapeId', shapeId)
            // verify required parameter 'shapeDtoRequest' is not null or undefined
            assertParamExists('updateShapeApiShapeShapeIdPut', 'shapeDtoRequest', shapeDtoRequest)
            const localVarPath = `/api/shape/{shape_id}`
                .replace(`{${"shape_id"}}`, encodeURIComponent(String(shapeId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shapeDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShapeApi - functional programming interface
 * @export
 */
export const ShapeApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShapeApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Shape
         * @param {ShapeDtoRequest} shapeDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createShapeApiShapePost(shapeDtoRequest: ShapeDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShapeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createShapeApiShapePost(shapeDtoRequest, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.createShapeApiShapePost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Shape
         * @param {string} shapeId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteShapeApiShapeShapeIdDelete(shapeId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteShapeApiShapeShapeIdDelete(shapeId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.deleteShapeApiShapeShapeIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Shapes
         * @param {string} imageDrawingId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllShapesApiShapeByImageDrawingImageDrawingIdGet(imageDrawingId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShapeDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllShapesApiShapeByImageDrawingImageDrawingIdGet(imageDrawingId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.getAllShapesApiShapeByImageDrawingImageDrawingIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Shapes
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllShapesApiShapeGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShapeDtoResponse>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllShapesApiShapeGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.getAllShapesApiShapeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Shape
         * @param {string} shapeId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShapeApiShapeShapeIdGet(shapeId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShapeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShapeApiShapeShapeIdGet(shapeId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.getShapeApiShapeShapeIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Link Shape With Connection
         * @param {string} shapeId 
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(shapeId: string, connectionId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(shapeId, connectionId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Link Shape With Diagram Component
         * @param {string} shapeId 
         * @param {string} diagramComponentId 
         * @param {DiagramComponentType} dcType 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost(shapeId: string, diagramComponentId: string, dcType: DiagramComponentType, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost(shapeId, diagramComponentId, dcType, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Unlink Shape From Connection
         * @param {string} shapeId 
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete(shapeId: string, connectionId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete(shapeId, connectionId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Unlink Shape From Diagram Component
         * @param {string} shapeId 
         * @param {string} diagramComponentId 
         * @param {DiagramComponentType} dcType 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete(shapeId: string, diagramComponentId: string, dcType: DiagramComponentType, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete(shapeId, diagramComponentId, dcType, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Shape
         * @param {string} shapeId 
         * @param {ShapeDtoRequest} shapeDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateShapeApiShapeShapeIdPut(shapeId: string, shapeDtoRequest: ShapeDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShapeDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateShapeApiShapeShapeIdPut(shapeId, shapeDtoRequest, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeApi.updateShapeApiShapeShapeIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShapeApi - factory interface
 * @export
 */
export const ShapeApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShapeApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Shape
         * @param {ShapeDtoRequest} shapeDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShapeApiShapePost(shapeDtoRequest: ShapeDtoRequest, tenantId?: string, options?: any): AxiosPromise<ShapeDto> {
            return localVarFp.createShapeApiShapePost(shapeDtoRequest, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Shape
         * @param {string} shapeId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShapeApiShapeShapeIdDelete(shapeId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteShapeApiShapeShapeIdDelete(shapeId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Shapes
         * @param {string} imageDrawingId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShapesApiShapeByImageDrawingImageDrawingIdGet(imageDrawingId: string, tenantId?: string, options?: any): AxiosPromise<Array<ShapeDto>> {
            return localVarFp.getAllShapesApiShapeByImageDrawingImageDrawingIdGet(imageDrawingId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Shapes
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShapesApiShapeGet(tenantId?: string, options?: any): AxiosPromise<ShapeDtoResponse> {
            return localVarFp.getAllShapesApiShapeGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Shape
         * @param {string} shapeId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShapeApiShapeShapeIdGet(shapeId: string, tenantId?: string, options?: any): AxiosPromise<ShapeDto> {
            return localVarFp.getShapeApiShapeShapeIdGet(shapeId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Link Shape With Connection
         * @param {string} shapeId 
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(shapeId: string, connectionId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(shapeId, connectionId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Link Shape With Diagram Component
         * @param {string} shapeId 
         * @param {string} diagramComponentId 
         * @param {DiagramComponentType} dcType 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost(shapeId: string, diagramComponentId: string, dcType: DiagramComponentType, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost(shapeId, diagramComponentId, dcType, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlink Shape From Connection
         * @param {string} shapeId 
         * @param {string} connectionId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete(shapeId: string, connectionId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete(shapeId, connectionId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Unlink Shape From Diagram Component
         * @param {string} shapeId 
         * @param {string} diagramComponentId 
         * @param {DiagramComponentType} dcType 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete(shapeId: string, diagramComponentId: string, dcType: DiagramComponentType, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete(shapeId, diagramComponentId, dcType, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Shape
         * @param {string} shapeId 
         * @param {ShapeDtoRequest} shapeDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShapeApiShapeShapeIdPut(shapeId: string, shapeDtoRequest: ShapeDtoRequest, tenantId?: string, options?: any): AxiosPromise<ShapeDto> {
            return localVarFp.updateShapeApiShapeShapeIdPut(shapeId, shapeDtoRequest, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShapeApi - object-oriented interface
 * @export
 * @class ShapeApi
 * @extends {BaseAPI}
 */
export class ShapeApi extends BaseAPI {
    /**
     * 
     * @summary Create Shape
     * @param {ShapeDtoRequest} shapeDtoRequest 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public createShapeApiShapePost(shapeDtoRequest: ShapeDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).createShapeApiShapePost(shapeDtoRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Shape
     * @param {string} shapeId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public deleteShapeApiShapeShapeIdDelete(shapeId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).deleteShapeApiShapeShapeIdDelete(shapeId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Shapes
     * @param {string} imageDrawingId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public getAllShapesApiShapeByImageDrawingImageDrawingIdGet(imageDrawingId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).getAllShapesApiShapeByImageDrawingImageDrawingIdGet(imageDrawingId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Shapes
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public getAllShapesApiShapeGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).getAllShapesApiShapeGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Shape
     * @param {string} shapeId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public getShapeApiShapeShapeIdGet(shapeId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).getShapeApiShapeShapeIdGet(shapeId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Link Shape With Connection
     * @param {string} shapeId 
     * @param {string} connectionId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(shapeId: string, connectionId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(shapeId, connectionId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Link Shape With Diagram Component
     * @param {string} shapeId 
     * @param {string} diagramComponentId 
     * @param {DiagramComponentType} dcType 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost(shapeId: string, diagramComponentId: string, dcType: DiagramComponentType, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).linkShapeWithDiagramComponentApiShapeShapeIdAttachDiagramComponentDiagramComponentIdPost(shapeId, diagramComponentId, dcType, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlink Shape From Connection
     * @param {string} shapeId 
     * @param {string} connectionId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete(shapeId: string, connectionId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete(shapeId, connectionId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Unlink Shape From Diagram Component
     * @param {string} shapeId 
     * @param {string} diagramComponentId 
     * @param {DiagramComponentType} dcType 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete(shapeId: string, diagramComponentId: string, dcType: DiagramComponentType, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).unlinkShapeFromDiagramComponentApiShapeShapeIdDetachDiagramComponentDiagramComponentIdDelete(shapeId, diagramComponentId, dcType, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Shape
     * @param {string} shapeId 
     * @param {ShapeDtoRequest} shapeDtoRequest 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeApi
     */
    public updateShapeApiShapeShapeIdPut(shapeId: string, shapeDtoRequest: ShapeDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeApiFp(this.configuration).updateShapeApiShapeShapeIdPut(shapeId, shapeDtoRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * ShapeTagApi - axios parameter creator
 * @export
 */
export const ShapeTagApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Create Shape Tag
         * @param {ShapeTagDtoRequest} shapeTagDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShapeTagApiShapeTagPost: async (shapeTagDtoRequest: ShapeTagDtoRequest, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeTagDtoRequest' is not null or undefined
            assertParamExists('createShapeTagApiShapeTagPost', 'shapeTagDtoRequest', shapeTagDtoRequest)
            const localVarPath = `/api/shapeTag/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shapeTagDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Delete Shape Tag
         * @param {string} shapeTagId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShapeTagApiShapeTagShapeTagIdDelete: async (shapeTagId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeTagId' is not null or undefined
            assertParamExists('deleteShapeTagApiShapeTagShapeTagIdDelete', 'shapeTagId', shapeTagId)
            const localVarPath = `/api/shapeTag/{shape_tag_id}`
                .replace(`{${"shape_tag_id"}}`, encodeURIComponent(String(shapeTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get All Shape Tags
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShapeTagsApiShapeTagGet: async (tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/shapeTag/`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Get Shape Tag
         * @param {string} shapeTagId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShapeTagApiShapeTagShapeTagIdGet: async (shapeTagId: string, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeTagId' is not null or undefined
            assertParamExists('getShapeTagApiShapeTagShapeTagIdGet', 'shapeTagId', shapeTagId)
            const localVarPath = `/api/shapeTag/{shape_tag_id}`
                .replace(`{${"shape_tag_id"}}`, encodeURIComponent(String(shapeTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 
         * @summary Update Shape Tag
         * @param {string} shapeTagId 
         * @param {ShapeTagDtoRequest} shapeTagDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShapeTagApiShapeTagShapeTagIdPut: async (shapeTagId: string, shapeTagDtoRequest: ShapeTagDtoRequest, tenantId?: string, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'shapeTagId' is not null or undefined
            assertParamExists('updateShapeTagApiShapeTagShapeTagIdPut', 'shapeTagId', shapeTagId)
            // verify required parameter 'shapeTagDtoRequest' is not null or undefined
            assertParamExists('updateShapeTagApiShapeTagShapeTagIdPut', 'shapeTagDtoRequest', shapeTagDtoRequest)
            const localVarPath = `/api/shapeTag/{shape_tag_id}`
                .replace(`{${"shape_tag_id"}}`, encodeURIComponent(String(shapeTagId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(shapeTagDtoRequest, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ShapeTagApi - functional programming interface
 * @export
 */
export const ShapeTagApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ShapeTagApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Create Shape Tag
         * @param {ShapeTagDtoRequest} shapeTagDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createShapeTagApiShapeTagPost(shapeTagDtoRequest: ShapeTagDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShapeTagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createShapeTagApiShapeTagPost(shapeTagDtoRequest, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeTagApi.createShapeTagApiShapeTagPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Delete Shape Tag
         * @param {string} shapeTagId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteShapeTagApiShapeTagShapeTagIdDelete(shapeTagId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteShapeTagApiShapeTagShapeTagIdDelete(shapeTagId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeTagApi.deleteShapeTagApiShapeTagShapeTagIdDelete']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get All Shape Tags
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAllShapeTagsApiShapeTagGet(tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<ShapeTagDto>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAllShapeTagsApiShapeTagGet(tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeTagApi.getAllShapeTagsApiShapeTagGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Get Shape Tag
         * @param {string} shapeTagId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getShapeTagApiShapeTagShapeTagIdGet(shapeTagId: string, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShapeTagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getShapeTagApiShapeTagShapeTagIdGet(shapeTagId, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeTagApi.getShapeTagApiShapeTagShapeTagIdGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * 
         * @summary Update Shape Tag
         * @param {string} shapeTagId 
         * @param {ShapeTagDtoRequest} shapeTagDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateShapeTagApiShapeTagShapeTagIdPut(shapeTagId: string, shapeTagDtoRequest: ShapeTagDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<ShapeTagDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateShapeTagApiShapeTagShapeTagIdPut(shapeTagId, shapeTagDtoRequest, tenantId, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['ShapeTagApi.updateShapeTagApiShapeTagShapeTagIdPut']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * ShapeTagApi - factory interface
 * @export
 */
export const ShapeTagApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ShapeTagApiFp(configuration)
    return {
        /**
         * 
         * @summary Create Shape Tag
         * @param {ShapeTagDtoRequest} shapeTagDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createShapeTagApiShapeTagPost(shapeTagDtoRequest: ShapeTagDtoRequest, tenantId?: string, options?: any): AxiosPromise<ShapeTagDto> {
            return localVarFp.createShapeTagApiShapeTagPost(shapeTagDtoRequest, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Delete Shape Tag
         * @param {string} shapeTagId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteShapeTagApiShapeTagShapeTagIdDelete(shapeTagId: string, tenantId?: string, options?: any): AxiosPromise<any> {
            return localVarFp.deleteShapeTagApiShapeTagShapeTagIdDelete(shapeTagId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get All Shape Tags
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAllShapeTagsApiShapeTagGet(tenantId?: string, options?: any): AxiosPromise<Array<ShapeTagDto>> {
            return localVarFp.getAllShapeTagsApiShapeTagGet(tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Get Shape Tag
         * @param {string} shapeTagId 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getShapeTagApiShapeTagShapeTagIdGet(shapeTagId: string, tenantId?: string, options?: any): AxiosPromise<ShapeTagDto> {
            return localVarFp.getShapeTagApiShapeTagShapeTagIdGet(shapeTagId, tenantId, options).then((request) => request(axios, basePath));
        },
        /**
         * 
         * @summary Update Shape Tag
         * @param {string} shapeTagId 
         * @param {ShapeTagDtoRequest} shapeTagDtoRequest 
         * @param {string} [tenantId] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateShapeTagApiShapeTagShapeTagIdPut(shapeTagId: string, shapeTagDtoRequest: ShapeTagDtoRequest, tenantId?: string, options?: any): AxiosPromise<ShapeTagDto> {
            return localVarFp.updateShapeTagApiShapeTagShapeTagIdPut(shapeTagId, shapeTagDtoRequest, tenantId, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ShapeTagApi - object-oriented interface
 * @export
 * @class ShapeTagApi
 * @extends {BaseAPI}
 */
export class ShapeTagApi extends BaseAPI {
    /**
     * 
     * @summary Create Shape Tag
     * @param {ShapeTagDtoRequest} shapeTagDtoRequest 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeTagApi
     */
    public createShapeTagApiShapeTagPost(shapeTagDtoRequest: ShapeTagDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeTagApiFp(this.configuration).createShapeTagApiShapeTagPost(shapeTagDtoRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Delete Shape Tag
     * @param {string} shapeTagId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeTagApi
     */
    public deleteShapeTagApiShapeTagShapeTagIdDelete(shapeTagId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeTagApiFp(this.configuration).deleteShapeTagApiShapeTagShapeTagIdDelete(shapeTagId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get All Shape Tags
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeTagApi
     */
    public getAllShapeTagsApiShapeTagGet(tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeTagApiFp(this.configuration).getAllShapeTagsApiShapeTagGet(tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Get Shape Tag
     * @param {string} shapeTagId 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeTagApi
     */
    public getShapeTagApiShapeTagShapeTagIdGet(shapeTagId: string, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeTagApiFp(this.configuration).getShapeTagApiShapeTagShapeTagIdGet(shapeTagId, tenantId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 
     * @summary Update Shape Tag
     * @param {string} shapeTagId 
     * @param {ShapeTagDtoRequest} shapeTagDtoRequest 
     * @param {string} [tenantId] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ShapeTagApi
     */
    public updateShapeTagApiShapeTagShapeTagIdPut(shapeTagId: string, shapeTagDtoRequest: ShapeTagDtoRequest, tenantId?: string, options?: RawAxiosRequestConfig) {
        return ShapeTagApiFp(this.configuration).updateShapeTagApiShapeTagShapeTagIdPut(shapeTagId, shapeTagDtoRequest, tenantId, options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * SsoApi - axios parameter creator
 * @export
 */
export const SsoApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * Generate login url and redirect
         * @summary Sso Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoLoginApiSsoLoginGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/sso/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * SsoApi - functional programming interface
 * @export
 */
export const SsoApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = SsoApiAxiosParamCreator(configuration)
    return {
        /**
         * Generate login url and redirect
         * @summary Sso Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async ssoLoginApiSsoLoginGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.ssoLoginApiSsoLoginGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['SsoApi.ssoLoginApiSsoLoginGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * SsoApi - factory interface
 * @export
 */
export const SsoApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = SsoApiFp(configuration)
    return {
        /**
         * Generate login url and redirect
         * @summary Sso Login
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        ssoLoginApiSsoLoginGet(options?: any): AxiosPromise<any> {
            return localVarFp.ssoLoginApiSsoLoginGet(options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * SsoApi - object-oriented interface
 * @export
 * @class SsoApi
 * @extends {BaseAPI}
 */
export class SsoApi extends BaseAPI {
    /**
     * Generate login url and redirect
     * @summary Sso Login
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof SsoApi
     */
    public ssoLoginApiSsoLoginGet(options?: RawAxiosRequestConfig) {
        return SsoApiFp(this.configuration).ssoLoginApiSsoLoginGet(options).then((request) => request(this.axios, this.basePath));
    }
}



/**
 * UsersApi - axios parameter creator
 * @export
 */
export const UsersApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeApiUsersMeGet: async (options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            const localVarPath = `/api/users/me`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            // authentication APIKeyCookie required


    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Запрос для получения jwt токена. Токен живет в течении 15 минут, по истечению времени жизни токена нужно повторно выполнить этот запрос для получения нового токена.
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [tenantId] 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginApiUsersLoginPost: async (username: string, password: string, tenantId?: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options: RawAxiosRequestConfig = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('loginApiUsersLoginPost', 'username', username)
            // verify required parameter 'password' is not null or undefined
            assertParamExists('loginApiUsersLoginPost', 'password', password)
            const localVarPath = `/api/users/login`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new URLSearchParams();

            if (tenantId != null) {
                localVarHeaderParameter['tenant-id'] = String(tenantId);
            }


            if (grantType !== undefined) { 
                localVarFormParams.set('grant_type', grantType as any);
            }
    
            if (username !== undefined) { 
                localVarFormParams.set('username', username as any);
            }
    
            if (password !== undefined) { 
                localVarFormParams.set('password', password as any);
            }
    
            if (scope !== undefined) { 
                localVarFormParams.set('scope', scope as any);
            }
    
            if (clientId !== undefined) { 
                localVarFormParams.set('client_id', clientId as any);
            }
    
            if (clientSecret !== undefined) { 
                localVarFormParams.set('client_secret', clientSecret as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'application/x-www-form-urlencoded';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams.toString();

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UsersApi - functional programming interface
 * @export
 */
export const UsersApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UsersApiAxiosParamCreator(configuration)
    return {
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getMeApiUsersMeGet(options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<UserDto>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getMeApiUsersMeGet(options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.getMeApiUsersMeGet']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
        /**
         * Запрос для получения jwt токена. Токен живет в течении 15 минут, по истечению времени жизни токена нужно повторно выполнить этот запрос для получения нового токена.
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [tenantId] 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async loginApiUsersLoginPost(username: string, password: string, tenantId?: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<any>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.loginApiUsersLoginPost(username, password, tenantId, grantType, scope, clientId, clientSecret, options);
            const localVarOperationServerIndex = configuration?.serverIndex ?? 0;
            const localVarOperationServerBasePath = operationServerMap['UsersApi.loginApiUsersLoginPost']?.[localVarOperationServerIndex]?.url;
            return (axios, basePath) => createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration)(axios, localVarOperationServerBasePath || basePath);
        },
    }
};

/**
 * UsersApi - factory interface
 * @export
 */
export const UsersApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UsersApiFp(configuration)
    return {
        /**
         * 
         * @summary Get Me
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getMeApiUsersMeGet(options?: any): AxiosPromise<UserDto> {
            return localVarFp.getMeApiUsersMeGet(options).then((request) => request(axios, basePath));
        },
        /**
         * Запрос для получения jwt токена. Токен живет в течении 15 минут, по истечению времени жизни токена нужно повторно выполнить этот запрос для получения нового токена.
         * @summary Login
         * @param {string} username 
         * @param {string} password 
         * @param {string} [tenantId] 
         * @param {string | null} [grantType] 
         * @param {string} [scope] 
         * @param {string | null} [clientId] 
         * @param {string | null} [clientSecret] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        loginApiUsersLoginPost(username: string, password: string, tenantId?: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: any): AxiosPromise<any> {
            return localVarFp.loginApiUsersLoginPost(username, password, tenantId, grantType, scope, clientId, clientSecret, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UsersApi - object-oriented interface
 * @export
 * @class UsersApi
 * @extends {BaseAPI}
 */
export class UsersApi extends BaseAPI {
    /**
     * 
     * @summary Get Me
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public getMeApiUsersMeGet(options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).getMeApiUsersMeGet(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Запрос для получения jwt токена. Токен живет в течении 15 минут, по истечению времени жизни токена нужно повторно выполнить этот запрос для получения нового токена.
     * @summary Login
     * @param {string} username 
     * @param {string} password 
     * @param {string} [tenantId] 
     * @param {string | null} [grantType] 
     * @param {string} [scope] 
     * @param {string | null} [clientId] 
     * @param {string | null} [clientSecret] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UsersApi
     */
    public loginApiUsersLoginPost(username: string, password: string, tenantId?: string, grantType?: string | null, scope?: string, clientId?: string | null, clientSecret?: string | null, options?: RawAxiosRequestConfig) {
        return UsersApiFp(this.configuration).loginApiUsersLoginPost(username, password, tenantId, grantType, scope, clientId, clientSecret, options).then((request) => request(this.axios, this.basePath));
    }
}



