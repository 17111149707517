import type Konva from 'konva'
import React, { type FC, useRef } from 'react'
import { Label, Tag as RKTag, Text } from 'react-konva'
import { tagApi } from '../../../../api/api-wrapper'
import type { ShapeTagDto, ShapeTagDtoRequest } from '../../../../generated/backend'
import { getAppData, useAppDispatch } from '../../../../store'
import { onDragEnd, onDragMove } from '../onDrag'

interface ITag {
  tagItem: ShapeTagDto
}

const Tag: FC<ITag> = ({ tagItem }) => {
  const ref = useRef<React.ElementRef<typeof Label>>(null)
  const dispatch = useAppDispatch()

  const onDragEndHandler = async (e: Konva.KonvaEventObject<DragEvent>): Promise<void> => {
    onDragEnd(e)

    const entityForUpdate: ShapeTagDtoRequest = {
      // @ts-expect-error id not in api
      id: tagItem.id,
      rotate: tagItem.rotate,
      x: e.target.attrs.x,
      y: e.target.attrs.y,
      label: tagItem.label,
      color: tagItem.color,
      shape_id: tagItem.shape_id,
    }

    // @ts-expect-error id not in api
    if (entityForUpdate.id) {
      // @ts-expect-error id not in api
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      await tagApi.updateShapeTagApiShapeTagShapeTagIdPut(entityForUpdate.id, entityForUpdate)
      await dispatch(getAppData())
    }
  }

  return (
    <Label
      ref={ref}
      id={tagItem.id}
      x={tagItem.x}
      y={tagItem.y}
      name={'SHAPE'}
      shapeType={'LABEL'}
      type={'LABEL'}
      onDragMove={e => {
        onDragMove(e)
      }}
      onDragEnd={onDragEndHandler}
      draggable
      entity={{
        ...tagItem,
        shapeType: 'TAG',
      }}
    >
      <RKTag
        fill={'white'}
        pointerDirection={'left-middle'}
        shadowColor={'black'}
        shadowBlur={10}
        shadowOffsetX={5}
        shadowOffsetY={5}
        shadowOpacity={0.5}
        strokeWidth={0}
        stroke={'black'}
        listening
      />
      <Text padding={6} fontSize={14} fill={'black'} text={tagItem.label} listening wrap='char' />
    </Label>
  )
}

export default Tag
