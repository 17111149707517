import { type ThemeConfig } from 'antd/es/config-provider/context'
const mainColor = '#215294'
const activeColor = '#38649F'

export const themeConfig: ThemeConfig = {
	components: {
		Button: {
			defaultHoverBg: activeColor,
			defaultActiveBg: activeColor,
			defaultBg: mainColor,
			defaultBorderColor: activeColor,
			defaultHoverBorderColor: activeColor,
		},
		Pagination: {
			colorText: '#eaeaea',
			itemInputBg: activeColor,
			colorBorder: activeColor,
		},
	},
}

export const ZOOM_STEP = 0.001

export const LINE_WIDTH = 11
export const LINE_HEIGHT = 11
export const DIFF_TO_SNAP = 11

export const ACTIVE_DEFAULT_COLOR = '#FFDD00'
export const REGULAR_DEFAULT_COLOR = '#FFDD0050'

export enum ApplicationMode {
	DEMO_APP = 'DEMO_APP',
	RBI_APP = 'RBI_APP',
	HAZOP_APP = 'HAZOP_APP',
}
