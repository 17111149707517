import React, { type ElementRef, type FC, useEffect, useRef, useState } from 'react'
import { Rect, Transformer } from 'react-konva'
import { setContextMenuPosition, setLabelPosition, useAppDispatch, useAppSelector } from '../../../store'
import { ShapeType } from '../../../generated/backend'
import { type Vector2d } from 'konva/lib/types'
import { LINE_WIDTH } from '../../../../consts'
import { TOOLS } from '../../header/tools/consts'
import { setIsDragEnabled } from '../../../store/slice/drawSlice'

type Anchor =
	| 'top-left'
	| 'top-center'
	| 'top-right'
	| 'middle-right'
	| 'middle-left'
	| 'bottom-left'
	| 'bottom-center'
	| 'bottom-right'

const SelectionTransformer: FC = () => {
	const dispatch = useAppDispatch()
	const trRef = useRef<ElementRef<typeof Transformer>>(null)

	const [anchors, setAnchors] = useState<Anchor[]>([])

	const selectedShapes = useAppSelector(state => state.draw.stage.selectedShapes)
	const selectedTool = useAppSelector(state => state.draw.stage.selectedTool)

	const defineAnchors = (): void => {
		if (selectedShapes.length === 1) {
			if (selectedShapes[0].shapeType === ShapeType.Line) {
				if (selectedShapes[0].width === LINE_WIDTH) {
					// если это вертикальная линия
					setAnchors(['top-center', 'bottom-center'])
				} else {
					setAnchors(['middle-left', 'middle-right']) // если горизонтальная
				}
			} else {
				setAnchors([
					'top-center',
					'top-left',
					'top-right',
					'bottom-center',
					'bottom-right',
					'bottom-left',
					'middle-right',
					'middle-left',
				])
			}
		}
	}

	const setMenuPosition = (): void => {
		const stage = trRef.current?.getStage()
		if (trRef.current && stage && (selectedShapes.length > 1 || selectedTool === TOOLS.SELECT)) {
			const position = {
				x: stage.container().offsetLeft + trRef.current.absolutePosition().x,
				y: stage.container().offsetTop + trRef.current.absolutePosition().y,
			}
			dispatch(setContextMenuPosition(position))
			dispatch(setLabelPosition(null))
		}
	}

	const showLabel = (): void => {
		const stage = trRef.current?.getStage()
		if (trRef.current && stage && (selectedShapes.length > 1 || selectedTool === TOOLS.SELECT)) {
			const position = {
				x: stage.container().offsetLeft + trRef.current.absolutePosition().x,
				y: stage.container().offsetTop + trRef.current.absolutePosition().y,
			}
			dispatch(setLabelPosition(position))
		}
	}

	const anchorDragBoundFunc = (oldPos: Vector2d, newPos: Vector2d, evt: unknown): Vector2d => {
		const pos = { ...newPos }
		if (selectedShapes.length === 1 && selectedShapes[0]?.shapeType === ShapeType.Line && trRef.current) {
			const hasIntersection = trRef.current.getStage()?.getIntersection(newPos)

			if (
				hasIntersection &&
				hasIntersection.attrs.id !== selectedShapes[0].id &&
				!hasIntersection.attrs.name.includes('anchor')
			) {
				if (hasIntersection.attrs.width === LINE_WIDTH) {
					pos.x =
						trRef.current
							.getStage()
							?.getAbsoluteTransform()
							.copy()
							.point({
								x: hasIntersection.attrs.x + 10,
								y: 0,
							}).x ?? 0
				} else {
					pos.y =
						trRef.current
							.getStage()
							?.getAbsoluteTransform()
							.copy()
							.point({
								x: 0,
								y: hasIntersection.attrs.y + 10,
							}).y ?? 0
				}
			}
		}
		return pos
	}

	useEffect(() => {
		const setNodesToTransformer = (): void => {
			if (trRef.current) {
				const layer = trRef.current.getLayer()
				const nodes = selectedShapes.map(shape => {
					return layer?.findOne('#' + shape.id)
				})
				// @ts-ignore
				trRef.current.nodes(nodes)
				trRef.current.getLayer()?.batchDraw()
			}
		}

		setNodesToTransformer()
		if (trRef.current) {
			trRef.current.on('contextmenu', setMenuPosition)
			trRef.current.on('dblclick', () => {
				defineAnchors()
				dispatch(setIsDragEnabled(true))
			})
			trRef.current.on('click', showLabel)
		}

		return () => {
			dispatch(setIsDragEnabled(false))
			setAnchors([])
		}
	}, [selectedShapes])

	return (
		<>
			<Transformer
				name='transformer'
				ref={trRef}
				boundBoxFunc={(oldBox, newBox) => {
					if (newBox.width < 5 || newBox.height < 5) {
						return oldBox
					}
					return newBox
				}}
				flipEnabled={false}
				rotateEnabled={false}
				resizeEnabled={selectedShapes.length === 1}
				enabledAnchors={anchors}
				anchorDragBoundFunc={anchorDragBoundFunc}
				borderDash={[4, 6]}
				onClick={e => {
					e.evt.preventDefault()
					showLabel()
				}}
				onContextMenu={e => {
					e.evt.preventDefault()
					setMenuPosition()
				}}
				shouldOverdrawWholeArea={true}
			/>
		</>
	)
}

export default SelectionTransformer
