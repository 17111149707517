import React, { FC, useEffect, useMemo, useState } from 'react'
import { useAppDispatch, useAppSelector } from '../../store'
import { DiagramComponent } from '../../generated/backend'
import {
	DemoDiagramComponentMapping,
	HazopDiagramComponentMapping,
	RbiDiagramComponentMapping,
} from '../../store/actions/common/types'
import { ApplicationMode } from '../../../consts'

const Label: FC = () => {
	const labelPosition = useAppSelector(state => state.draw.stage.labelPosition)
	const selectedShapes = useAppSelector(state => state.draw.stage.selectedShapes)
	const contextMenuPosition = useAppSelector(state => state.draw.stage.contextMenuPosition)
	const applicationMode = useAppSelector(state => state.draw.applicationMode)

	const sortedEntities = useMemo(() => {
		return [...(selectedShapes[0]?.entitiesForLabels ?? [])]?.sort((a, b) => {
			if (applicationMode === ApplicationMode.DEMO_APP) {
				return -1
			}

			if (applicationMode === ApplicationMode.HAZOP_APP) {
				if (a.diagram_component_type === 'HazopNode') {
					return -1
				}
				if (b.diagram_component_type === 'HazopNode') {
					return -1
				}
			}

			if (applicationMode === ApplicationMode.RBI_APP) {
				if (a.diagram_component_type === 'RbiCorrosionLoop') {
					return -1
				}
				if (b.diagram_component_type === 'RbiCorrosionLoop') {
					return -1
				}
			}

			return 0
		})
	}, [selectedShapes])

	if (!labelPosition || contextMenuPosition || !sortedEntities?.length) return

	return (
		<div
			style={{
				position: 'absolute',
				top: labelPosition.y - 55,
				left: labelPosition.x,
				boxShadow: '2px 2px 20px 2px rgb(149 149 149)',
				padding: '5px',
				backgroundColor: 'white',
				textAlign: 'center',
			}}
		>
			{sortedEntities?.map(entity => entity.name).join(' | ')}
		</div>
	)
}

export default Label
