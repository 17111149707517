import type Konva from 'konva'
import { type Vector2d } from 'konva/lib/types'
import React, { type FC, useEffect, useRef, useState } from 'react'
import { Rect } from 'react-konva'
import type { ApplicationMode } from '../../../../../consts'
import { DIFF_TO_SNAP, LINE_HEIGHT, LINE_WIDTH } from '../../../../../consts'
import { connectionApi, shapeApi } from '../../../../api/api-wrapper'
import { type ConnectionDto, type ShapeDtoRequest, ShapeType } from '../../../../generated/backend'
import {
	getAppData,
	setContextMenuPosition,
	setHoveringDiagramComponent,
	setSelectedShapes,
	useAppDispatch,
	useAppSelector,
} from '../../../../store'
import type { DiagramComponentMapping } from '../../../../store/actions/common/types'
import { type AppShape, setLabelPosition } from '../../../../store/slice/drawSlice'
import EntitiesLabel from '../EntitiesLabel'
import { getRelatedEntities, getRelatedEntity } from '../helpers'
import { onDragEnd, onDragMove } from '../onDrag'
import { onMouseLeaveChangeCursor, onMouseOverChangeCursor } from '../onMouse'

interface ILine {
	line: AppShape
}

const Line: FC<ILine> = ({ line }) => {
	const [lineIsHovering, setLineIsHovering] = useState(false)
	const [entitiesPosition, setEntitiesPosition] = useState({ x: line.x, y: line.y })

	const applicationMode = useAppSelector(state => state.draw.applicationMode)
	const hoveringDiagramComponent = useAppSelector(state => state.draw.stage.hoveringDiagramComponent)
	const selectedTool = useAppSelector(state => state.draw.stage.selectedTool)
	const isDragEnabled = useAppSelector(state => state.draw.stage.isDragEnabled)
	const lineRef = useRef<React.ElementRef<typeof Rect>>(null)
	const dispatch = useAppDispatch()

	useEffect(() => {
		if (lineRef.current) {
			const entity = getRelatedEntities(applicationMode as ApplicationMode, line)
			const entitiesIds = entity?.map(entity => entity?.id)
			if (entitiesIds?.includes(hoveringDiagramComponent)) {
				lineRef.current.setAttr('fill', line.colors.active)
				setLineIsHovering(true)
			} else {
				lineRef.current.setAttr('fill', line.colors.regular)
				setLineIsHovering(false)
			}
		}
	}, [hoveringDiagramComponent])

	const onMouseOver = (e: Konva.KonvaEventObject<MouseEvent>): void => {
		const entity = getRelatedEntity(applicationMode as ApplicationMode, line)

		if (entity?.id) {
			dispatch(setHoveringDiagramComponent(entity.id))
		}
		onMouseOverChangeCursor(e, selectedTool)
	}

	const onMouseLeave = (e: Konva.KonvaEventObject<MouseEvent>): void => {
		dispatch(setHoveringDiagramComponent(null))
		onMouseLeaveChangeCursor(e)
	}

	const onClick = (): void => {
		dispatch(setLabelPosition(null))
		dispatch(setContextMenuPosition(null))
		dispatch(setSelectedShapes([line]))
	}

	const getNewLineAttrs = (e: Konva.KonvaEventObject<Event>): AppShape => {
		const newLineAttrs = {
			...line,
			x: e.target.attrs.x,
			y: e.target.attrs.y,
		}

		if (line.width === LINE_WIDTH) {
			newLineAttrs.width = LINE_WIDTH
			newLineAttrs.height = line.height * e.target.attrs.scaleY
		}

		if (line.height === LINE_HEIGHT) {
			newLineAttrs.width = line.width * e.target.attrs.scaleX
			newLineAttrs.height = LINE_HEIGHT
		}

		return newLineAttrs
	}

	const updateLine = async (line: AppShape): Promise<void> => {
		const entityForUpdate: ShapeDtoRequest = {
			height: line.height,
			image_drawing_id: line.image_drawing_id,
			label: line.label,
			points: line.points,
			shape_type: line.shape_type,
			width: line.width,
			x: line.x,
			y: line.y,
			rotate: line.rotate,
		}

		if (line.id) {
			await shapeApi.updateShapeApiShapeShapeIdPut(line.id, entityForUpdate)
		}
	}

	const checkPrevConnections = async (line: AppShape, connection: ConnectionDto): Promise<void> => {
		let hasIntersection: boolean
		if (connection.y && connection.x) {
			if (line.width === LINE_WIDTH) {
				hasIntersection = connection.y >= line.y && connection.y <= line.y + line.height
			} else {
				hasIntersection = connection.x >= line.x && connection.x <= line.x + line.width
			}
		}

		if (!hasIntersection! && connection.id && connection.shapes_id) {
			await shapeApi.unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete(line.id, connection.id)
			if (connection.shapes_id.length === 2) {
				const secondShapeId = connection.shapes_id.filter(shapeId => shapeId !== line.id)[0]
				await shapeApi.unlinkShapeFromConnectionApiShapeShapeIdDetachConnectionConnectionIdDelete(
					secondShapeId,
					connection.id
				)
				await connectionApi.deleteConnectionApiConnectionConnectionIdDelete(connection.id)
			}
		}
	}

	const lineHaveNewIntersection = (line: AppShape): AppShape[] => {
		const allShapes: AppShape[] = []
		if (lineRef.current) {
			const startLine: Vector2d | undefined = lineRef.current
				.getStage()
				?.getAbsoluteTransform()
				.copy()
				.point({ x: line.x, y: line.y })
			const endLine: Vector2d | undefined = lineRef.current
				.getStage()
				?.getAbsoluteTransform()
				.copy()
				.point({ x: line.x + line.width, y: line.y + line.height })

			const shapesOnLineStart = lineRef.current.getStage()?.getAllIntersections(startLine)
			const shapesOnLineEnd = lineRef.current.getStage()?.getAllIntersections(endLine)

			const index1 = shapesOnLineStart?.findIndex(shape => shape.attrs.shapeType === 'Connector')
			const index2 = shapesOnLineEnd?.findIndex(shape => shape.attrs.shapeType === 'Connector')

			if (index1 && shapesOnLineStart && index1 > -1) {
				allShapes.push(shapesOnLineStart[index1].attrs.entity as AppShape)
			} else if (shapesOnLineStart) {
				shapesOnLineStart.forEach(shape => {
					if (shape.attrs.shapeType && shape.attrs.id !== line.id) {
						allShapes.push(shape.attrs.entity as AppShape)
					}
				})
			}

			if (shapesOnLineEnd && index2 && index2 > -1) {
				allShapes.push(shapesOnLineEnd[index2].attrs.entity as AppShape)
			} else if (shapesOnLineEnd && index2) {
				shapesOnLineEnd.forEach(shape => {
					if (shape.attrs.shapeType && shape.attrs.id !== line.id) {
						allShapes.push(shape.attrs.entity as AppShape)
					}
				})
			}
		}

		return allShapes
	}

	const createNewIntersectionWithConnector = async (connector: ConnectionDto): Promise<void> => {
		if (connector.id) {
			try {
				await shapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(line.id, connector.id)
			} catch (e) {
				console.error('createNewIntersectionWithConnector', e)
			}
		}
	}

	const updateConnectorInRectangle = async (shape: AppShape): Promise<void> => {
		if (shape.connections[0].id) {
			try {
				await shapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(
					line.id,
					shape.connections[0].id
				)
			} catch (e) {
				console.error('updateConnectorInRectangle', e, shape, line)
			}
		}
	}

	const createConnectorWithRectangle = async (shape: AppShape): Promise<void> => {
		try {
			const res = await connectionApi.createConnectionApiConnectionPost({
				x: shape.x + shape.width / 2,
				y: shape.y + shape.height / 2,
				color: 'blue',
				width: 15,
				height: 15,
			})
			if (res.data.id) {
				await shapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(shape.id, res.data.id)
				await shapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(line.id, res.data.id)
			}
		} catch (e) {
			console.error('createConnectorWithRectangle', e)
		}
	}

	const createNewIntersection = async (shape: AppShape, line: AppShape): Promise<void> => {
		// eslint-disable-next-line @typescript-eslint/ban-ts-comment
		// @ts-expect-error
		if (shape?.shape_type === 'Connector' && !shape.shapesId.includes(line.id)) {
			await createNewIntersectionWithConnector(shape as unknown as ConnectionDto)
		} else if (shape.shape_type === ShapeType.Rectangle && shape.connections.length && shape.connections[0].id) {
			await updateConnectorInRectangle(shape)
		} else if (shape.shape_type === ShapeType.Rectangle) {
			await createConnectorWithRectangle(shape)
		} else if (shape.shape_type === ShapeType.Line) {
			if (line.width === LINE_WIDTH && shape.width !== LINE_WIDTH) {
				const res = await connectionApi.createConnectionApiConnectionPost({
					x: line.x + LINE_WIDTH / 2,
					y: shape.y + LINE_HEIGHT / 2,
					color: 'blue',
					width: 15,
					height: 15,
				})
				if (res.data.id) {
					await shapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(shape.id, res.data.id)
					await shapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(line.id, res.data.id)
				}
			} else if (line.height === LINE_HEIGHT && shape.height !== LINE_HEIGHT) {
				const res = await connectionApi.createConnectionApiConnectionPost({
					x: shape.x,
					y: line.y,
					color: 'blue',
					width: 15,
					height: 15,
				})
				if (res.data.id) {
					await shapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(shape.id, res.data.id)
					await shapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(line.id, res.data.id)
				}
			} else {
				let connection!: ConnectionDto
				// если линия горизонтальная
				if (line.height === LINE_HEIGHT) {
					// если перемещаемая линия стыкуется с другой своим началом
					if (Math.abs(line.x - (shape.x + shape.width)) < DIFF_TO_SNAP && shape.x < line.x + line.width / 2) {
						// shape.x && line.y
						const res = await connectionApi.createConnectionApiConnectionPost({
							x: line.x,
							y: shape.y + LINE_HEIGHT / 2,
							color: 'blue',
							width: 15,
							height: 15,
						})
						connection = res.data
					}
					// если перемещаемая линия стыкуется с другой своим концом
					if (
						Math.abs(line.x + line.width - shape.x) < DIFF_TO_SNAP &&
						shape.x + shape.width > line.x + line.width / 2
					) {
						// shape.x + shape.width && line.y
						const res = await connectionApi.createConnectionApiConnectionPost({
							x: line.x + line.width,
							y: shape.y + LINE_HEIGHT / 2,
							color: 'blue',
							width: 15,
							height: 15,
						})
						connection = res.data
					}
				}
				// если линия вертикальная
				if (line.width === LINE_WIDTH) {
					// если перемещаемая линия стыкуется с другой своим началом
					if (Math.abs(line.y - (shape.y + shape.height)) < DIFF_TO_SNAP && shape.y < line.y + line.height / 2) {
						const res = await connectionApi.createConnectionApiConnectionPost({
							x: shape.x + LINE_WIDTH / 2,
							y: line.y,
							color: 'blue',
							width: 15,
							height: 15,
						})
						connection = res.data
					}
					// если перемещаемая линия стыкуется с другой своим концом
					if (
						Math.abs(line.y + line.height - shape.y) < DIFF_TO_SNAP &&
						shape.y + shape.height > line.y + line.height / 2
					) {
						const res = await connectionApi.createConnectionApiConnectionPost({
							x: shape.x + LINE_WIDTH / 2,
							y: line.y + line.height,
							color: 'blue',
							width: 15,
							height: 15,
						})
						connection = res.data
					}
				}
				if (connection?.id) {
					await shapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(shape.id, connection.id)
					await shapeApi.linkShapeWithConnectionApiShapeShapeIdAttachConnectionConnectionIdPost(line.id, connection.id)
				}
			}
		}
	}

	const onTransformEnd = async (e: Konva.KonvaEventObject<Event>): Promise<void> => {
		const newLine = getNewLineAttrs(e)

		if (newLine?.image_drawing_id && applicationMode) {
			for (const connection of line.connections) {
				await checkPrevConnections(newLine, connection)
			}

			const shapes = lineHaveNewIntersection(newLine)
			for (const shape of shapes) {
				await createNewIntersection(shape, newLine)
			}

			await updateLine(newLine)
			await dispatch(getAppData())
			e.target.setAttr('scaleX', 1)
			e.target.setAttr('scaleY', 1)
		}
	}

	const renderEntitiesLabels = (): React.JSX.Element => {
		const labels = {} as DiagramComponentMapping<typeof applicationMode>
		if (line.entitiesForLabels && line.entitiesForLabels.length > 0) {
			for (const entity of line.entitiesForLabels) {
				if (labels) {
					// @ts-expect-error any
					labels[entity.diagram_component_type] = [entity]
				}
			}
		}
		let rotation = 0
		let x = entitiesPosition.x
		let y = entitiesPosition.y
		const lineIsVertical = line.width === LINE_WIDTH
		const lineIsHorizontal = line.height === LINE_HEIGHT
		if (lineIsVertical) {
			rotation = 90
			if (line.tags?.length) {
				const tag = line.tags[0]
				const tagOnLeft = tag.x ? tag.x < line.x : false
				if (tagOnLeft) {
					x = x + 30
				}
			}
		}
		if (lineIsHorizontal) {
			y = y - 15
			if (line.tags?.length) {
				const tag = line.tags[0]
				const tagAboveLine = tag.y ? tag.y < line.y : false
				if (tagAboveLine) {
					y = y + 30
				}
			}
		}
		return (
			<EntitiesLabel
				appMode={applicationMode}
				hovering={lineIsHovering}
				rotate={rotation}
				entities={labels}
				position={{ x, y }}
			/>
		)
	}

	return (
		<>
			<Rect
				id={line.id}
				x={line.x}
				y={line.y}
				width={line.width}
				height={line.height}
				fill={line.colors.regular}
				shapeType={ShapeType.Line}
				hitStrokeWidth={15}
				entity={line}
				name='SHAPE'
				connections={line.connections}
				onMouseOver={onMouseOver}
				onMouseLeave={onMouseLeave}
				onClick={onClick}
				onDragStart={e => {
					if (!isDragEnabled) {
						e.target.setAttrs({ x: line.x, y: line.y })
						e.target.stopDrag()
						return
					}
				}}
				onDragMove={e => {
					if (!isDragEnabled) return
					onDragMove(e)
					setEntitiesPosition(e.target.getPosition())
				}}
				onDragEnd={async e => {
					if (!isDragEnabled) return
					onDragEnd(e)
					await onTransformEnd(e)
				}}
				onTransformEnd={onTransformEnd}
				ref={lineRef}
			/>
			{renderEntitiesLabels()}
		</>
	)
}

export default Line
